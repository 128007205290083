/* ===========================================
   imports : 
   Import colors from avalon.
   Import breakpoints from avalon.
   Import global from avalon.
   ========================================= */
/* ===========================================
   imports
   ========================================= */
/* ********* Variables ********* */
/* ===========================================
   settings.global
   ========================================= */
/**
 * High-level settings and variables,
 * that are accessible in whole Avalon project
*/
/* ===========================================
   settings.breakpoints
   ========================================= */
/* ===========================================
   settings.colors
   ========================================= */
/* New theming colors (use these in future, convert old colors along the way)
   ========================================================================== */
/* Color variables used in AvalonComponents, Poker and Casino projects
/* (Old theming colors, should be slowly removed)
   ========================================================================== */
/* Corporate colors
   ========================================================================== */
/* Poker colors
   ========================================================================== */
/* Casino colors
   ========================================================================== */
/* Bingo colors
   ========================================================================== */
/* Casino colors
   ========================================================================== */
/* Oddset colors
   ========================================================================== */
/* Virtuel colors
   ========================================================================== */
/* EuroJackpot colors
   ========================================================================== */
/* Set Color Variables
   ========================================================================== */
/* Components
   ========================================================================== */
/* *********** MIXINS ********** */
/* ===========================================
   tools.mixins
   ========================================= */
.consent-popup .consent-popup_wrapper--hide {
  opacity: 0;
  pointer-events: none;
}
.consent-popup .consent-popup_wrapper--show {
  opacity: 1;
  pointer-events: all;
}
.consent-popup .consent-popup__wrapper {
  position: relative;
  justify-content: center;
  overflow: hidden;
  transition: 1s;
}
.consent-popup .consent-popup__wrapper .consent-popup__closebutton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  height: 2rem;
  width: 2rem;
}
.consent-popup .consent-popup__wrapper .consent-popup__shadow {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  background-color: #000000;
  z-index: 9987;
  opacity: 0.4;
}
.consent-popup .consent-popup__wrapper .consent-popup__content {
  margin-left: auto;
  margin-right: auto;
  max-height: 99%;
  overflow: hidden;
  z-index: 9988;
  text-align: center;
  background: #ffffff;
}
.consent-popup .consent-popup__wrapper .consent-popup__content .consent-popup__tooltip {
  cursor: pointer;
  position: relative;
  display: inline-block;
  font-weight: bold;
}
.consent-popup .consent-popup__wrapper .consent-popup__content .consent-popup__tooltip:hover::before {
  content: attr(data-label);
  position: absolute;
  bottom: 2.5rem;
  left: 50%;
  width: 20rem;
  font-size: 1.1rem;
  line-height: 1.4;
  background: black;
  color: white;
  padding: 0.8rem 0.8rem;
  border-radius: 0.7rem;
  transform: translateX(-50%);
}
.consent-popup .consent-popup__wrapper .consent-popup__content .consent-popup__tooltip:hover::after {
  content: "";
  position: absolute;
  bottom: 1.5rem;
  left: 50%;
  margin-left: -0.5rem;
  border-width: 0.5rem;
  border-style: solid;
  border-color: black transparent transparent;
}
.consent-popup .consent-popup__wrapper .consent-popup__content .consent-popup__element--show {
  top: 2vh;
}
.consent-popup .consent-popup__wrapper .consent-popup__content .consent-popup__element--hide {
  top: -100%;
}
.consent-popup .consent-popup__wrapper .consent-popup__content .consent-popup__element {
  position: fixed;
  width: 90vw;
  margin: 0 auto;
  /* Will not center vertically and won't work in IE6/7. */
  left: 0;
  right: 0;
  box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
  border-radius: 0.1rem;
  transition: 1.2s;
  transition-timing-function: ease-out;
  z-index: 9989;
  max-height: 96vh;
  background-color: inherit;
  padding: 5vw;
  font-size: 1rem;
}
@media screen and (min-width: 950px) {
  .consent-popup .consent-popup__wrapper .consent-popup__content .consent-popup__element {
    padding: 2rem 10rem;
    width: 80rem;
    max-width: 100%;
    font-size: 1.5rem;
  }
}
.consent-popup .consent-popup__wrapper .consent-popup__content .consent-popup__element p {
  font-size: 0.9em;
}
.consent-popup .consent-popup__wrapper .consent-popup__content .consent-popup__element h1 {
  font-size: 2.3em;
}
.consent-popup .consent-popup__wrapper .consent-popup__content .consent-popup__element h2 {
  font-size: 2.2em;
}
.consent-popup .consent-popup__wrapper .consent-popup__content .consent-popup__element h3 {
  font-size: 1.8em;
}
.consent-popup .consent-popup__wrapper .consent-popup__content .consent-popup__element h4 {
  font-size: 1.5em;
}
.consent-popup .consent-popup__wrapper .consent-popup__content .consent-popup__element h5 {
  font-size: 1.3em;
}
.consent-popup .consent-popup__wrapper .consent-popup__content .consent-popup__element h6 {
  font-size: 1.1em;
}
.consent-popup .consent-popup__wrapper .consent-popup__content .consent-popup__element a {
  font-size: 0.9em;
}
.consent-popup .consent-popup__wrapper .consent-popup__loader {
  opacity: 0.7;
  background-color: black;
}
.consent-popup .consent-popup__wrapper .consent-popup__cta-overflow {
  border-radius: 2rem;
  border: solid 2px transparent;
  height: 4.2rem;
  overflow: hidden;
  width: 21rem;
  text-decoration: none;
  font-weight: 600;
  display: block;
  margin: auto;
  margin-top: 2rem;
}
@media screen and (min-width: 950px) {
  .consent-popup .consent-popup__wrapper .consent-popup__cta-overflow--left {
    float: left;
  }
  .consent-popup .consent-popup__wrapper .consent-popup__cta-overflow--right {
    float: right;
  }
}
.consent-popup .consent-popup__wrapper .consent-popup__cta-overflow .consent-popup__cta {
  display: inline-table;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #000000;
}
.consent-popup .consent-popup__wrapper .consent-popup__cta-overflow .consent-popup__cta:hover {
  text-decoration: none;
}
.consent-popup .consent-popup__wrapper .consent-popup__cta-overflow .consent-popup__cta .consent-popup__cta-container {
  display: table-cell;
  vertical-align: middle;
}
.consent-popup .consent-popup__wrapper .consent-popup__cta-overflow .consent-popup__cta .consent-popup__cta-container p {
  margin: 0;
  padding: 0;
  font-size: 1.3rem;
}
.consent-popup .consent-popup__wrapper .consent-popup__cta-overflow .consent-popup__cta .consent-popup__cta-container .consent-popup__cta-loader-box {
  position: absolute;
  transform: translateY(1rem) translateX(1rem) scale(1);
  opacity: 1;
  transition: transform 0.25s, opacity 0.15s;
}
.consent-popup .consent-popup__wrapper .consent-popup__cta-overflow .consent-popup__cta .consent-popup__cta-container .consent-popup__cta-loader-box .loader-text {
  display: inline-block;
}
.consent-popup .consent-popup__wrapper .consent-popup__cta-overflow .consent-popup__cta .consent-popup__cta-container .consent-popup__cta-loader-box .loader-animation-box {
  margin-left: 0.5rem;
  display: inline-block;
}
@keyframes loader-dot-animation {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-100%);
  }
}
.consent-popup .consent-popup__wrapper .consent-popup__cta-overflow .consent-popup__cta .consent-popup__cta-container .consent-popup__cta-loader-box .loader-animation-box .loader-dot {
  width: 1.2vw;
  height: 1.2vw;
  margin-left: 0.3rem;
  border-radius: 50%;
  background-color: #000000;
  display: inline-block;
}
.consent-popup .consent-popup__wrapper .consent-popup__cta-overflow .consent-popup__cta .consent-popup__cta-container .consent-popup__cta-loader-box .loader-animation-box .loader-dot:nth-child(1n) {
  animation: loader-dot-animation 0.5s ease-in-out -0.66s infinite;
}
.consent-popup .consent-popup__wrapper .consent-popup__cta-overflow .consent-popup__cta .consent-popup__cta-container .consent-popup__cta-loader-box .loader-animation-box .loader-dot:nth-child(2n) {
  animation: loader-dot-animation 0.5s ease-in-out -0.33s infinite;
}
.consent-popup .consent-popup__wrapper .consent-popup__cta-overflow .consent-popup__cta .consent-popup__cta-container .consent-popup__cta-loader-box .loader-animation-box .loader-dot:nth-child(3n) {
  animation: loader-dot-animation 0.5s ease-in-out -1s infinite;
}
@media screen and (min-width: 950px) {
  .consent-popup .consent-popup__wrapper .consent-popup__cta-overflow .consent-popup__cta .consent-popup__cta-container .consent-popup__cta-loader-box .loader-animation-box .loader-dot {
    width: 0.8rem;
    height: 0.8rem;
  }
}
