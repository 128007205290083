.header.top-navigation {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  transition: transform 0.4s ease;
  -webkit-transition: transform 0.4s ease;
  font-size: 1.4rem;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}
@media print {
  .header.top-navigation {
    display: none;
  }
}
.header.top-navigation.header--not-fixed {
  position: absolute;
}
.mode-edit .header.top-navigation {
  position: relative;
}
.header.top-navigation * {
  box-sizing: border-box;
}
.header.top-navigation a:hover {
  text-decoration: none;
}
/* OVERLAY */
.header__cta-component__read-more-overlay {
  background: #fff;
  top: 10%;
  margin-top: 40%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  padding: 1em 4em;
  border-radius: 0.7em;
  text-align: center;
  width: 90%;
  max-width: calc(1440px / 3);
}
@media screen and (max-width: 950px) {
  .header__cta-component__read-more-overlay {
    margin-top: 10%;
  }
}
.header__cta-component__read-more-overlay__title {
  font-size: 22px;
  font-size: 2.2rem;
}
.header__cta-component__read-more-overlay__paragraph {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
}
.header__cta-component__read-more-overlay__close-btn {
  background: #156a5f;
  color: #fff;
  font-size: 10px;
  font-size: 1rem;
  display: inline-block;
  padding: 13px 16px 16px 16px;
  margin-bottom: 8px;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
  /*&:active {
    background: @color-white-100;
    color: @color-gray-100;
  }

  &--inactive {
    background: @color-gray-400;
    color: @color-gray-300;
  }

  &--medium {
  }

  &-small {
  }

  @media screen and (min-width: @medium) {
    .font-size(14);
  }*/
  min-width: 100px;
  margin: 2em 0;
}
.header__cta-component__read-more-overlay__close-btn:hover {
  background: #004b32;
  text-decoration: none;
}
.header__cta-component__read-more-overlay__close-icon {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 0;
  margin: 20px 20px;
  font-size: 26px;
  font-size: 2.6rem;
  font-weight: 400;
  cursor: pointer;
}
.header-static-fix {
  height: 6.6rem;
}
@media (min-width: 950px) {
  .header-static-fix {
    height: 11.6rem;
  }
}
.is-logged-in:not(.is-context-neutral) .header-static-fix {
  height: 8.4rem;
}
@media (min-width: 768px) {
  .is-logged-in:not(.is-context-neutral) .header-static-fix {
    height: 8.8rem;
  }
}
@media (min-width: 950px) {
  .is-logged-in:not(.is-context-neutral) .header-static-fix {
    height: 11.6rem;
  }
}
.region-kundecenter .header-static-fix {
  height: 8.6rem;
}
@media (min-width: 950px) {
  .region-kundecenter .header-static-fix {
    height: 13.6rem;
  }
}
@media (min-width: 950px) {
  .region-avalon-kundecenter .header-static-fix {
    height: 2.6rem;
  }
}
@media (min-width: 1440px) {
  .region-avalon-kundecenter .header-static-fix {
    height: 4rem;
  }
}
.region-frontpage .header-static-fix {
  height: 6.6rem;
}
@media (min-width: 950px) {
  .region-frontpage .header-static-fix {
    height: 28.6rem;
  }
}
.region-avalon-frontpage .header-static-fix {
  height: 6.6rem;
}
@media (min-width: 950px) {
  .region-avalon-frontpage .header-static-fix {
    height: 4.2rem;
  }
}
@media (min-width: 950px) {
  .region-retail-account.PlayerAccountManagementContentPage .header-static-fix {
    height: 12rem;
  }
}
.region-retail-account.RetailAccountHiddenMenu .header-static-fix {
  display: none;
}
.region-retail-account .is-logged-in:not(.is-context-neutral) .header-static-fix {
  height: 6.6rem;
}
@media (min-width: 950px) {
  .region-retail-account .is-logged-in:not(.is-context-neutral) .header-static-fix {
    height: 7.6rem;
  }
}
.region-retail-account .header-static-fix {
  height: 6.6rem;
}
@media (min-width: 950px) {
  .region-retail-account .header-static-fix {
    height: 7.6rem !important;
  }
}
.corporate-nav {
  position: relative;
  transition: transform 0.4s ease;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
@media all and (min-width: 950px) {
  .corporate-nav {
    height: 4rem;
  }
}
.corporate-nav .js-prevlogintime span:not(:empty):before {
  content: 'Seneste login: ';
}
.corporate-nav__container {
  height: 6.6rem;
}
@media all and (min-width: 950px) {
  .corporate-nav__container {
    max-width: 1440px;
    margin: 0 auto;
    min-width: 923px;
    overflow: hidden;
    height: auto;
  }
  .is-logged-in .corporate-nav__container {
    min-width: 950px;
  }
}
.corporate-nav__logo {
  position: absolute;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  display: block;
}
@media all and (min-width: 950px) {
  .corporate-nav__logo {
    transform: none;
    left: auto;
    top: 1.2rem;
    margin-left: 0;
    display: inline-block;
  }
  .corporate-nav__logo-img {
    height: 1.7rem;
    width: 10.6rem;
  }
  .corporate-nav__logo.mobile {
    display: none;
  }
}
.corporate-nav__navigation {
  width: auto;
  height: 100%;
  -ms-transform: none;
  transform: none;
  background-color: transparent;
  display: inline-block;
  margin-left: 180px;
  box-shadow: none;
}
.corporate-nav__list {
  margin: 0;
  padding: 0;
  max-height: 100%;
  width: 100%;
}
.corporate-nav__list-item {
  list-style: none;
  font-size: 4vw;
  float: left;
}
.is-mobile-detected .corporate-nav__list-item.desktop-only,
.is-tablet-detected .corporate-nav__list-item.desktop-only {
  display: none;
}
.corporate-nav__list-item:not(.js-has-subnav) .corporate-nav__list-link:hover {
  color: white;
  opacity: 0.7;
}
@media all and (min-width: 950px) {
  .corporate-nav__list-item.has-shown-subnav .sub-navigation {
    transform: translateY(100%) scaleY(1);
    z-index: 1;
  }
}
@media all and (min-width: 950px) {
  .corporate-nav__list-item.has-shown-subnav .corporate-nav__list-link:after {
    transform: translateY(0) rotate(225deg);
    transform: translateY(0);
    border-bottom-color: #fff;
  }
}
.corporate-nav__list-item.has-shown-subnav svg.corporate-nav__link-icon {
  transform: rotate(0.5turn);
}
.corporate-nav__list-link {
  font-size: 1.4rem;
  padding: 2.3rem 1.8rem;
  line-height: 1.2;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  position: relative;
  display: block;
}
@media all and (min-width: 950px) {
  .corporate-nav__list-link {
    padding: 1.3rem 1.8rem 1.1rem;
  }
}
.corporate-nav__list-link svg.corporate-nav__link-icon {
  fill: white;
  height: 1.1rem;
  width: 1.1rem;
  margin-left: 0.6rem;
  display: none;
  transition: transform 150ms ease-out;
}
@media all and (min-width: 950px) {
  .corporate-nav__list-link svg.corporate-nav__link-icon {
    display: inline-block;
  }
}
@media all and (min-width: 950px) {
  .corporate-nav__list-link:after {
    height: 0;
    width: 0;
    content: ' ';
    display: block;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid transparent;
    position: absolute;
    bottom: -3px;
    left: 50%;
    margin-left: -8px;
    -ms-transform: translateY(1000%);
    transform: translateY(1000%);
    z-index: 0;
    transition: transform 200ms ease;
  }
}
@media all and (min-width: 950px) {
  .corporate-nav .sub-navigation {
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    max-height: none;
    transition: all 200ms ease;
    background-color: #FFF;
    overflow: hidden;
    transform-origin: top;
    transform: translateY(100%) scaleY(0);
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1);
    z-index: 0;
  }
}
.corporate-nav .sub-navigation__inner {
  max-width: 1440px;
  margin: 0 auto;
}
.corporate-nav .sub-navigation__list {
  padding: 0;
  margin: 0;
  box-sizing: content-box !important;
  display: inline-block;
  padding-left: 235px;
}
.corporate-nav .sub-navigation__item {
  font-size: 14px;
  display: inline-block;
  list-style: none;
  float: left;
}
.corporate-nav .sub-navigation__link {
  padding: 15px 25px;
  color: #000;
  text-decoration: none;
  font-weight: 600;
  display: inline-block;
  min-height: 0;
}
.corporate-nav .sub-navigation__link:hover {
  opacity: 0.5;
}
.user-bar {
  float: right;
  display: inline-block;
}
.user-bar__navigation {
  width: auto;
  height: 100%;
  display: inline-block;
}
.user-bar__list {
  margin: 0;
  padding: 0;
  max-height: 100%;
  list-style-type: none;
}
.accountmenu__item {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 12.5px 10px 9px;
  line-height: 1.3;
  cursor: pointer;
  transition: 0.2s;
  color: #fff;
  text-decoration: none;
}
.accountmenu__item:hover {
  opacity: 0.7;
  cursor: pointer;
}
.accountmenu .signup-btn {
  position: relative;
}
.accountmenu .login-btn.mobile-only {
  display: none;
}
.accountmenu .info-bar-item--ballance,
.accountmenu .info-bar-item--username {
  display: none;
}
.accountmenu .profile-btn {
  position: relative;
  float: right;
  height: 40px;
  width: 40px;
  padding: 0;
  display: none;
}
.accountmenu .profile-btn.mobile-only {
  display: none;
  color: transparent;
  width: 16vw;
  height: 16vw;
  margin-right: 3vw;
  top: -1.7rem;
}
.accountmenu .profile-btn:before {
  content: ' ';
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAxMC43IDEwLjgiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEwLjcgMTAuODsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGw9IiNGRkYiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTAsOC42QzkuOSw4LjUsNy4zLDcuMiw1LjMsNy4yUzAuOCw4LjUsMC43LDguNkMwLjUsOC43LDAuMyw4LjksMC4zLDkuMXYwLjZjMCwwLjMsMC4zLDAuNiwwLjYsMC42aDguOGMwLjMsMCwwLjYtMC4zLDAuNi0wLjZWOS4xQzEwLjMsOC45LDEwLjIsOC43LDEwLDguNnogTTIuOCw0LjFWMi45YzAtMS40LDEuMS0yLjUsMi41LTIuNXMyLjUsMS4xLDIuNSwyLjV2MS4yYzAsMS40LTEuMSwyLjUtMi41LDIuNVMyLjgsNS41LDIuOCw0LjF6Ii8+PC9zdmc+DQo=');
}
.has-shown-subnav .accountmenu .mega-menu {
  transform: translateY(100%) scaleY(1);
  z-index: 1;
}
@media all and (min-width: 950px) {
  .is-logged-in .profile-btn,
  .is-logged-in .info-bar-item--ballance,
  .is-logged-in .info-bar-item--username {
    display: inline-block;
  }
}
.is-logged-in .login-btn,
.is-logged-in .signup-btn {
  display: none;
}
.is-logged-in .accountmenu:hover .accountmenu__item {
  opacity: 0.7;
  cursor: pointer;
}
.top-navigation .sub-sub-sub-navigation {
  max-height: none;
  transition: max-height 0.4s ease;
}
.top-navigation .corporate-nav__list-item.notification-item a {
  padding-right: 2.5rem;
  position: relative;
  display: inline-block;
}
@media all and (min-width: 768px) {
  .top-navigation .corporate-nav__list-item.notification-item a {
    display: block;
    padding-right: 5rem;
  }
}
@media all and (min-width: 950px) {
  .top-navigation .corporate-nav__list-item.notification-item a {
    display: block;
    padding-right: 2rem;
  }
}
.top-navigation .corporate-nav__list-item.notification-item a:before {
  pointer-events: none;
  position: absolute;
  top: 0.6rem;
  right: 0;
  width: 1.8rem;
  height: 1.8rem;
  content: ' ';
  display: block;
  vertical-align: middle;
  font-size: 1.4rem;
  border-radius: 50%;
  background: #feb700 url('/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons/icon_pam_exclamation-black.svg') center center no-repeat;
  background-size: 0.8rem;
}
@media all and (min-width: 768px) and (max-width: 949px) {
  .top-navigation .corporate-nav__list-item.notification-item a:before {
    top: 1rem;
    width: 4rem;
    height: 4rem;
    background-size: 2rem;
  }
}
@media all and (min-width: 950px) {
  .top-navigation .corporate-nav__list-item.notification-item a:before {
    top: 0.6rem;
    width: 1.5rem;
    height: 1.5rem;
    background-size: 0.75rem;
  }
}
.brand-nav {
  position: relative;
  transition: transform 200ms ease;
  overflow: visible;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
@media all and (min-width: 950px) {
  .brand-nav .user-bar {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .brand-nav .desktop-only {
    display: none;
  }
}
@media (min-width: 950px) {
  .brand-nav .mobile-only {
    display: none;
  }
}
.brand-nav__container {
  white-space: nowrap;
  height: 6.6rem;
}
@media all and (min-width: 950px) {
  .brand-nav__container {
    max-width: 1440px;
    margin: 0 auto;
    height: 7.6rem;
  }
}
.brand-nav__container .pam-return-to-site-close {
  width: 3rem;
  height: 3rem;
  margin-left: 1rem;
  border: 2px solid white;
  border-radius: 50%;
  position: absolute;
  right: 2rem;
  top: 2.2rem;
  cursor: pointer;
  display: none;
}
.brand-nav__container .pam-return-to-site-close__icon {
  width: 50%;
  height: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.brand-nav__logo {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 21rem);
  height: 6.5rem;
  margin: 0 10rem 0 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.is-logged-in .brand-nav__logo {
  width: calc(100% - 13rem);
}
@media all and (min-width: 950px) {
  .brand-nav__logo {
    width: 17rem;
    margin: 0;
    height: 7.5rem;
    left: auto;
  }
}
.brand-nav__logo .pam-return-to-site-back {
  display: none;
  cursor: pointer;
}
.brand-nav__logo .pam-return-to-site-back:before,
.brand-nav__logo .pam-return-to-site-back:after {
  content: "";
  display: table;
}
.brand-nav__logo .pam-return-to-site-back:after {
  clear: both;
}
.brand-nav__logo .pam-return-to-site-back__brand {
  float: left;
  margin-left: 1.5rem;
  color: white;
  font-weight: 600;
  font-size: 1.6rem;
  transform: translateY(0.3rem);
}
.brand-nav__logo .pam-return-to-site-back__brand:hover {
  text-decoration: underline;
}
.brand-nav__logo .pam-return-to-site-back__logo-img {
  display: none;
  max-height: 2rem;
  max-width: 13rem;
  width: auto;
  height: auto;
}
@media all and (min-width: 950px) {
  .brand-nav__logo .pam-return-to-site-back__logo-img {
    display: inline-block;
  }
}
.brand-nav__logo .pam-return-to-site-back .brand-nav__logo-text {
  display: block;
  font-size: 0.9rem;
  color: white;
  margin: -0.5rem 0 0.5rem;
  font-style: italic;
  font-weight: normal;
}
.brand-nav__logo .pam-return-to-site-back__icon {
  width: 1.1rem;
  height: 1.1rem;
  float: left;
  position: relative;
  top: 1rem;
}
.brand-nav__logo a {
  text-decoration: none;
}
.brand-nav__logo-cube {
  width: 4vw;
  height: 4vw;
  display: inline-block;
}
@media all and (min-width: 950px) {
  .brand-nav__logo-cube {
    display: none;
  }
}
.brand-nav__logo-img {
  padding-left: 1rem;
  display: inline-block;
  margin: 0;
  max-height: 2.5rem;
  max-width: 17rem;
  height: 100%;
}
@media (max-width: 440px) {
  .brand-nav__logo-img {
    max-height: 1.7rem;
    max-width: calc(100% - 1.8rem);
  }
}
@media all and (min-width: 950px) {
  .brand-nav__logo-img {
    border: 0;
    margin: 0;
    text-align: left;
    padding-left: 0;
  }
}
.brand-nav__logo-text {
  display: block;
  font-size: 0.8rem;
  color: white;
  margin: 0.5rem 0;
  font-style: italic;
}
.brand-nav__navigation {
  margin-left: 180px;
  width: auto;
  height: 100%;
  transform: none;
  background-color: transparent;
  display: inline-block;
}
.brand-nav__list {
  margin: 0;
  padding: 0;
  overflow: hidden;
  max-height: 100%;
  height: 100%;
}
.brand-nav__item {
  font-size: 2.1rem;
  display: inline-block;
  list-style: none;
  float: left;
}
.brand-nav__item:not(.js-has-subnav) .brand-nav__link:hover {
  opacity: 0.7;
}
@media all and (min-width: 950px) {
  .brand-nav__item.has-shown-subnav .sub-navigation {
    transform: translateY(100%) scaleY(1);
    transition-timing-function: ease-out;
    z-index: 1;
  }
}
.brand-nav__item.has-shown-subnav svg.brand-nav__link-icon {
  transform: rotate(0.5turn);
}
.brand-nav__link {
  padding: 2.9rem 1.8rem 2.8rem;
  font-weight: 600;
  font-size: 1.6rem;
  display: inline-block;
  border-bottom: 0;
  color: white;
  position: relative;
  text-decoration: none;
  transition: all 0.2s ease;
}
.brand-nav__link:hover {
  text-decoration: none;
}
.brand-nav__link svg.brand-nav__link-icon {
  fill: white;
  height: 1.1rem;
  width: 1.1rem;
  margin-left: 0.6rem;
  display: none;
  transition: transform 150ms ease-out;
}
@media all and (min-width: 950px) {
  .brand-nav__link svg.brand-nav__link-icon {
    display: inline-block;
  }
}
@media all and (min-width: 950px) {
  .js-has-subnav .brand-nav__link:after {
    height: 0;
    width: 0;
    content: ' ';
    display: block;
    border-left: 0.8rem solid transparent;
    border-right: 0.8rem solid transparent;
    border-bottom: 0.8rem solid transparent;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -0.8rem;
    transform: translateY(1000%);
    z-index: 0;
    transition: transform 200ms ease;
  }
}
.has-shown-subnav .brand-nav__link:after {
  transform: translateY(0) rotate(225deg);
}
@media all and (min-width: 768px) and (max-width: 949px) {
  .has-shown-subnav .brand-nav__link:after {
    transform: translateY(0.25rem) rotate(225deg);
  }
}
@media all and (min-width: 950px) {
  .has-shown-subnav .brand-nav__link:after {
    transform: translateY(0);
    border-bottom-color: white;
  }
}
@media all and (min-width: 950px) {
  .brand-nav .sub-navigation {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    min-width: 950px;
    background-color: white;
    transform-origin: top;
    transform: translateY(100%) scaleY(0);
    max-height: none !important;
    transition: all 200ms ease;
    z-index: 0;
  }
}
.brand-nav .sub-navigation__inner {
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 17rem;
}
.brand-nav .sub-navigation__list {
  padding: 0;
  display: inline-block;
}
.brand-nav .sub-navigation__item {
  font-size: 1.6rem;
  display: inline-block;
  list-style: none;
  float: left;
}
.brand-nav .sub-navigation__link {
  color: #000000;
  text-decoration: none;
  font-weight: 600;
  display: inline-block;
}
@media all and (min-width: 950px) {
  .brand-nav .sub-navigation__link {
    padding: 1.9rem 1.8rem 1.1rem;
    min-height: 0;
  }
}
.brand-nav .sub-navigation__link:hover {
  opacity: 0.5;
}
@media all and (min-width: 950px) {
  .region-player-account-management.Register .brand-nav,
  .region-player-account-management.ChangePasswordPage .brand-nav,
  .region-retail-account.page-retail-upgrade .brand-nav {
    display: none;
  }
}
.mobile-nav-right {
  display: none;
}
@media all and (max-width: 949px) {
  .is-logged-in .mobile-nav-right {
    display: block;
  }
}
.mobile-navigation-header {
  color: #FFF;
  width: 100%;
  text-align: center;
  font-weight: bold;
  padding: 0;
  justify-content: center;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  height: 6.6rem;
}
@media (min-width: 950px) {
  .mobile-navigation-header {
    display: none;
  }
}
.mobile-navigation-header__logo {
  width: 100%;
  display: block;
}
.mobile-navigation-header .mobile-navigation-header__logo-link {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile-navigation-header .mobile-navigation-header__logo-cube {
  width: 3rem;
  height: 3rem;
}
.mobile-navigation-header .mobile-navigation-header__brand-logo {
  height: 2.45rem;
}
.mobile-navigation-header__logo-img {
  padding-left: 1.5rem;
  max-height: 2.5rem;
}
@media (min-width: 768px) {
  .mobile-navigation-header__logo-img {
    height: 3rem;
  }
}
.mobile-navigation-header__logo-text {
  display: none;
}
.menu-toggle {
  float: left;
}
@media all and (min-width: 950px) {
  .menu-toggle {
    display: none;
  }
}
@media screen and (-ms-high-contrast: active) and (max-width: 30em), (-ms-high-contrast: none) and (max-width: 30em) {
  .menu-toggle {
    z-index: 1;
    position: relative;
  }
}
.menu-toggle-right {
  position: fixed;
  right: 0;
  top: 0;
  display: none;
}
.menu-toggle-right .menu-toggle-btn div:nth-child(2) {
  transform: translateX(200%);
  -webkit-transform: translateX(200%);
  z-index: -1;
  opacity: 0;
}
.menu-toggle-btn {
  width: 16.2vw;
  height: 16.2vw;
  display: block;
  box-sizing: border-box;
  padding: 4vw 4vw;
  position: relative;
  overflow: visible;
}
.menu-toggle-btn div {
  width: 6vw;
  height: 3px;
  background-color: #FFF;
  display: inline-block;
  transition: transform 0.4s ease;
  -webkit-transition: transform 0.4s ease;
  border-radius: 0.4vw;
  position: absolute;
  top: calc(50%);
  left: 50%;
  margin-left: -3vw;
  transform: translate(0, 0) rotate(0);
  -webkit-transform: translate(0, 0) rotate(0);
}
.menu-toggle-btn div:nth-child(1) {
  margin-top: -2vw;
}
.menu-toggle-btn div:nth-child(3) {
  margin-top: 2vw;
}
.menu-toggle-btn.active div:nth-child(1) {
  transform: translate(-0.3vw, 2.25vw) rotate(45deg);
  -webkit-transform: translate(-0.3vw, 2.25vw) rotate(45deg);
}
.menu-toggle-btn.active div:nth-child(2) {
  transform: translateX(-300%);
  -webkit-transform: translateX(-300%);
}
.menu-toggle-btn.active div:nth-child(3) {
  transform: translate(-0.3vw, -1.76vw) rotate(-45deg);
  -webkit-transform: translate(-0.3vw, -1.76vw) rotate(-45deg);
}
.mobile-user-info {
  clear: both;
  width: 100%;
  display: none;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.1vw 2vw;
  box-sizing: border-box;
}
.is-logged-in .mobile-user-info {
  display: block;
}
@media all and (min-width: 950px) {
  .is-logged-in .mobile-user-info {
    display: none;
  }
}
.mobile-user-info.modal-wrapper.overlay-active:before {
  pointer-events: none;
  background-color: initial;
}
.mobile-user-info:after {
  display: table;
  content: ' ';
  clear: both;
}
.mobile-user-info .js-balance-total {
  font-weight: bold;
  text-align: right;
  white-space: nowrap;
}
.mobile-user-info .js-balance-total:not(:empty):before {
  font-weight: normal;
  content: 'Saldo: ';
}
.mobile-user-info__left,
.mobile-user-info__right {
  display: inline-block;
  vertical-align: middle;
}
.mobile-user-info__left p,
.mobile-user-info__right p,
.mobile-user-info__left a,
.mobile-user-info__right a {
  margin: 0;
  padding: 0.5vw 0;
  color: #FFF;
  font-size: 2.7vw;
  line-height: 1;
}
@media all and (min-width: 768px) and (max-width: 949px) {
  .mobile-user-info__left p,
  .mobile-user-info__right p,
  .mobile-user-info__left a,
  .mobile-user-info__right a {
    font-size: 14px;
  }
}
.mobile-user-info__left {
  float: left;
}
.mobile-user-info__right {
  float: right;
}
.games-mobile-nav {
  position: fixed;
  background-color: #014B32;
  z-index: 20;
}
.games-mobile-nav .corporate-nav__list-link:before {
  display: none !important;
}
@media all and (min-width: 950px) {
  .games-mobile-nav {
    display: none;
  }
}
.games-mobile-nav a {
  color: #fff;
}
.games-mobile-nav a:after {
  border-color: #fff !important;
  border-width: 2px !important;
}
.games-mobile-nav a[class*=-link] {
  z-index: 90;
}
.games-mobile-nav .sub-navigation {
  background-color: #fff !important;
  max-height: none !important;
  height: 0;
  overflow: scroll !important;
  -webkit-overflow-scrolling: touch;
  transition: height 0.4s ease !important;
}
.games-mobile-nav .sub-navigation .games-left,
.games-mobile-nav .sub-navigation .games-center,
.games-mobile-nav .sub-navigation .games-right {
  width: 100% !important;
  max-width: 100% !important;
  float: none;
  padding: 1rem 2% 4vw;
}
.games-mobile-nav .sub-navigation .games-left p,
.games-mobile-nav .sub-navigation .games-center p,
.games-mobile-nav .sub-navigation .games-right p {
  font-size: 2.5vw;
}
.games-mobile-nav .sub-navigation .games-left .games-list,
.games-mobile-nav .sub-navigation .games-center .games-list,
.games-mobile-nav .sub-navigation .games-right .games-list {
  margin-top: 0;
}
.games-mobile-nav .sub-navigation .games-left .games-list-item,
.games-mobile-nav .sub-navigation .games-center .games-list-item,
.games-mobile-nav .sub-navigation .games-right .games-list-item {
  width: calc(50% - 1rem);
  max-height: none;
  height: 12vw;
}
.games-mobile-nav .sub-navigation .games-left .header__cta-component__description,
.games-mobile-nav .sub-navigation .games-center .header__cta-component__description,
.games-mobile-nav .sub-navigation .games-right .header__cta-component__description {
  white-space: pre-line;
  font-size: 4vw;
}
.games-mobile-nav .sub-navigation .games-left .header__cta-component__cta-home,
.games-mobile-nav .sub-navigation .games-center .header__cta-component__cta-home,
.games-mobile-nav .sub-navigation .games-right .header__cta-component__cta-home {
  padding: 3.6vw 5vw 2.5vw;
  font-size: 4vw;
}
.games-mobile-nav .sub-navigation .games-right {
  padding-top: 4vw !important;
}
@media all and (max-width: 949px) {
  .header.is-showing-left-menu {
    transform: translateX(84.6vw) !important;
    -webkit-transform: translateX(84.6vw) !important;
  }
  .header.is-showing-right-menu {
    transform: translateX(-100vw) !important;
    -webkit-transform: translateX(-100vw) !important;
  }
  .header.is-showing-right-menu:before {
    transform: translateX(15.4vw);
    -webkit-transform: translateX(15.4vw);
  }
  .header.is-showing-right-menu .menu-toggle-right {
    position: fixed;
    display: block;
    margin-top: 0;
    right: -15.4vw;
  }
  .header.is-showing-right-menu .profile-btn .mobile-only {
    display: none;
  }
  .header {
    pointer-events: all;
  }
  nav:first-of-type {
    display: none;
  }
  nav:last-of-type {
    display: block;
    max-width: 100vw;
  }
  .corporate-nav__list-item.js-has-subnav:first-child {
    display: none;
  }
  .brand-nav,
  .corporate-nav {
    overflow: hidden;
    padding: 0;
  }
  .brand-nav .user-bar,
  .corporate-nav .user-bar {
    display: block;
  }
  .brand-nav .user-bar .desktop-only,
  .corporate-nav .user-bar .desktop-only {
    display: none;
  }
  .brand-nav .user-bar__item,
  .corporate-nav .user-bar__item {
    position: relative;
    line-height: 1;
    color: #FFF;
    font-size: 1.1rem;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    padding: 0;
    margin-right: 3vw;
    float: right;
  }
  .brand-nav .user-bar__login-icon,
  .corporate-nav .user-bar__login-icon {
    height: 2rem;
    width: 2rem;
    position: absolute;
    top: 1.4rem;
    left: calc(50% - 1rem);
  }
  .brand-nav .user-bar .login-btn.mobile-only,
  .corporate-nav .user-bar .login-btn.mobile-only {
    display: inline-block;
    position: relative;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    padding: 0;
    margin-right: 3vw;
    float: right;
  }
  .brand-nav .user-bar .login-btn.mobile-only:before,
  .corporate-nav .user-bar .login-btn.mobile-only:before {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    white-space: nowrap;
    line-height: 1;
  }
  .is-logged-in .brand-nav .user-bar .login-btn.mobile-only,
  .is-logged-in .corporate-nav .user-bar .login-btn.mobile-only {
    display: none;
  }
  .is-logged-in .brand-nav .user-bar .create-btn.mobile-only,
  .is-logged-in .corporate-nav .user-bar .create-btn.mobile-only {
    display: none;
  }
  .brand-nav .user-bar .profile-btn.mobile-only,
  .corporate-nav .user-bar .profile-btn.mobile-only {
    display: none;
    background: #fff;
    border-radius: 50%;
    position: relative;
  }
  .brand-nav .user-bar .profile-btn.mobile-only:before,
  .corporate-nav .user-bar .profile-btn.mobile-only:before {
    content: " ";
    width: 4vw;
    height: 4.5vw;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -56%);
    -ms-transform: translate(-50%, -56%);
    transform: translate(-50%, -56%);
    display: inline-block;
    background-position: 50%;
    background-repeat: no-repeat;
  }
  .is-logged-in .brand-nav .user-bar .profile-btn.mobile-only,
  .is-logged-in .corporate-nav .user-bar .profile-btn.mobile-only {
    display: block;
  }
  .brand-nav__logo-text,
  .corporate-nav__logo-text {
    display: none;
    text-decoration: none;
  }
  .brand-nav .corporate-nav__logo,
  .corporate-nav .corporate-nav__logo {
    text-align: center;
  }
  .brand-nav .corporate-nav__logo img,
  .corporate-nav .corporate-nav__logo img {
    max-height: 2.5rem;
  }
  .brand-nav__navigation,
  .corporate-nav__navigation {
    position: fixed;
    margin-left: 0;
    width: 84.6vw;
    height: 100vh;
    left: 0;
    top: 0;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  .brand-nav__navigation.mobile-nav-left,
  .corporate-nav__navigation.mobile-nav-left {
    transform: translateX(-84.6vw);
    -webkit-transform: translateX(-84.6vw);
  }
  .brand-nav__navigation.mobile-nav-right,
  .corporate-nav__navigation.mobile-nav-right {
    transform: translateX(115.4vw);
    -webkit-transform: translateX(115.4vw);
  }
  .is-tablet-detected .brand-nav__navigation,
  .is-tablet-detected .corporate-nav__navigation {
    width: 50vw;
  }
  .is-tablet-detected .brand-nav__navigation.mobile-nav-left,
  .is-tablet-detected .corporate-nav__navigation.mobile-nav-left {
    transform: translateX(-50vw);
    -webkit-transform: translateX(-50vw);
  }
  .is-tablet-detected .brand-nav__navigation.mobile-nav-right,
  .is-tablet-detected .corporate-nav__navigation.mobile-nav-right {
    transform: translateX(100vw);
    -webkit-transform: translateX(100vw);
  }
  .brand-nav__list,
  .corporate-nav__list {
    background-color: #FFF;
    max-height: 100vh;
    min-height: 100vh;
    padding-bottom: 80vw;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: relative;
    z-index: 1;
  }
  .brand-nav__item,
  .corporate-nav__item,
  .brand-nav__list-item,
  .corporate-nav__list-item {
    font-size: 1.6rem;
    width: 100%;
  }
  .brand-nav__link,
  .corporate-nav__link,
  .brand-nav__list-link,
  .corporate-nav__list-link {
    display: block;
    border-bottom: 1px solid #eee;
    color: #323232;
    font-size: inherit;
  }
  .user-bar .brand-nav__link,
  .user-bar .corporate-nav__link,
  .user-bar .brand-nav__list-link,
  .user-bar .corporate-nav__list-link {
    color: #fff;
    border-bottom: none;
  }
  .js-has-subnav .brand-nav__link:after,
  .js-has-subnav .corporate-nav__link:after,
  .js-has-subnav .brand-nav__list-link:after,
  .js-has-subnav .corporate-nav__list-link:after {
    position: absolute;
    display: block;
    z-index: 100;
    content: ' ';
    width: 1rem;
    height: 1rem;
    top: 3.7rem;
    left: auto;
    right: 3rem;
    border-right: 2px solid #323232;
    border-bottom: 2px solid #323232;
    transform: translateY(-50%) rotate(45deg);
    transition: all 0.4s ease;
  }
  .js-has-subnav.has-shown-subnav .brand-nav__link:after,
  .js-has-subnav.has-shown-subnav .corporate-nav__link:after,
  .js-has-subnav.has-shown-subnav .brand-nav__list-link:after,
  .js-has-subnav.has-shown-subnav .corporate-nav__list-link:after,
  .js-has-subnav.active .brand-nav__link:after,
  .js-has-subnav.active .corporate-nav__link:after,
  .js-has-subnav.active .brand-nav__list-link:after,
  .js-has-subnav.active .corporate-nav__list-link:after {
    transform: translateY(0) rotate(225deg);
  }
  .brand-nav .sub-navigation,
  .corporate-nav .sub-navigation {
    max-height: 0;
    transition: max-height 0.4s ease;
    background-color: #f0f0f0;
    overflow: hidden;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1);
  }
  .brand-nav .sub-navigation__inner,
  .corporate-nav .sub-navigation__inner {
    padding-left: 0;
  }
  .brand-nav .sub-navigation__list,
  .corporate-nav .sub-navigation__list {
    width: 100%;
  }
  .brand-nav .sub-navigation__item,
  .corporate-nav .sub-navigation__item {
    display: block;
    width: 100%;
    padding-left: 3rem;
  }
  .brand-nav .sub-navigation__link,
  .corporate-nav .sub-navigation__link {
    padding: 2rem 1.8rem;
    color: #000;
  }
  .brand-nav .mobile-nav-right .mega-menu__account-details:last-of-type,
  .corporate-nav .mobile-nav-right .mega-menu__account-details:last-of-type,
  .brand-nav .mobile-nav-right .mega-menu__account-details__divider,
  .corporate-nav .mobile-nav-right .mega-menu__account-details__divider,
  .brand-nav .mobile-nav-right .last-login,
  .corporate-nav .mobile-nav-right .last-login,
  .brand-nav .mobile-nav-right .desktop-only,
  .corporate-nav .mobile-nav-right .desktop-only,
  .brand-nav .mobile-nav-right .welcome-text,
  .corporate-nav .mobile-nav-right .welcome-text {
    display: none;
  }
  .brand-nav .mobile-nav-right .mega-menu__account-details__row--right,
  .corporate-nav .mobile-nav-right .mega-menu__account-details__row--right {
    float: right;
  }
  .brand-nav .mobile-nav-right .mobile-navigation-header .corporate-nav__logo-img,
  .corporate-nav .mobile-nav-right .mobile-navigation-header .corporate-nav__logo-img {
    height: 2.2rem;
  }
  .brand-nav .mobile-nav-right .mobile-navigation-header .profile-btn,
  .corporate-nav .mobile-nav-right .mobile-navigation-header .profile-btn {
    background: #fff;
    border-radius: 50%;
    height: 9vw;
    width: 9vw;
    position: relative;
    top: 3.5vw;
  }
  .brand-nav .mobile-nav-right .mobile-navigation-header .profile-btn:before,
  .corporate-nav .mobile-nav-right .mobile-navigation-header .profile-btn:before {
    content: " ";
    width: 4vw;
    height: 4vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    background-position: 50%;
    background-repeat: no-repeat;
  }
  .brand-nav .mobile-nav-right .mobile-navigation-header .profile-btn:after,
  .corporate-nav .mobile-nav-right .mobile-navigation-header .profile-btn:after {
    position: absolute;
    content: ' ';
    left: 0;
    bottom: -3.5vw;
    width: 100%;
    height: 0.4rem;
    background-color: #FFF;
  }
  .brand-nav .mobile-nav-right .mega-menu__account,
  .corporate-nav .mobile-nav-right .mega-menu__account {
    padding: 0 6vw;
  }
  .brand-nav .mobile-nav-right .mega-menu__account-details__title,
  .corporate-nav .mobile-nav-right .mega-menu__account-details__title {
    margin-bottom: 0;
  }
  .brand-nav .mobile-nav-right .mega-menu__account-details__title__inner,
  .corporate-nav .mobile-nav-right .mega-menu__account-details__title__inner {
    font-size: 4vw;
  }
  .brand-nav .mobile-nav-right .mega-menu__account-details__large-text,
  .corporate-nav .mobile-nav-right .mega-menu__account-details__large-text {
    font-size: 5vw;
  }
  .brand-nav .mobile-nav-right .mega-menu__account-details__right,
  .corporate-nav .mobile-nav-right .mega-menu__account-details__right {
    float: right;
  }
  .brand-nav .mobile-nav-right .mega-menu__account-details__logout-btn,
  .corporate-nav .mobile-nav-right .mega-menu__account-details__logout-btn {
    font-size: 3.5vw;
  }
  .brand-nav .mobile-nav-right .mega-menu__account-details__details-btn,
  .corporate-nav .mobile-nav-right .mega-menu__account-details__details-btn {
    font-size: 3.5vw;
    line-height: 1.5;
  }
  .brand-nav .mobile-nav-right .mega-menu__account-details__button,
  .corporate-nav .mobile-nav-right .mega-menu__account-details__button {
    width: 100%;
    font-size: 4vw;
    display: block;
    margin-left: 0;
    text-align: center;
  }
  .brand-nav .mobile-nav-right .mega-menu__account-meta,
  .corporate-nav .mobile-nav-right .mega-menu__account-meta {
    white-space: initial;
  }
  .brand-nav .mobile-nav-right .sub-navigation-inner,
  .corporate-nav .mobile-nav-right .sub-navigation-inner {
    background: #FFF;
    max-height: 100vh;
    min-height: 100vh;
    padding-bottom: 50vw;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .brand-nav .mobile-nav-right .sub-navigation-inner ul,
  .corporate-nav .mobile-nav-right .sub-navigation-inner ul,
  .brand-nav .mobile-nav-right .sub-navigation-inner li,
  .corporate-nav .mobile-nav-right .sub-navigation-inner li {
    margin: 0;
    padding: 0;
  }
  .brand-nav .mobile-nav-right .mega-menu__section:first-of-type,
  .corporate-nav .mobile-nav-right .mega-menu__section:first-of-type {
    margin-top: 4vw;
    border-top: 1px solid #ccc;
  }
  .brand-nav .mobile-nav-right .mega-menu__section__title > .navigation-item-link,
  .corporate-nav .mobile-nav-right .mega-menu__section__title > .navigation-item-link {
    display: block;
    color: inherit;
    padding: 6vw 6vw 4vw;
    cursor: default;
    outline: none;
    text-decoration: none;
    font-size: 5vw;
    font-weight: 600;
    border-bottom: 1px solid #ccc;
    text-align: center;
  }
  .brand-nav .mobile-nav-right .mega-menu__link-list__item__link,
  .corporate-nav .mobile-nav-right .mega-menu__link-list__item__link {
    display: block;
    padding: 4vw 6vw;
    border-bottom: 1px solid #ccc;
    font-size: 4vw;
    font-weight: 600;
    text-decoration: none;
    color: #333333;
  }
}
@media all and (max-width: 949px) and (min-width: 950px) {
  .js-has-subnav .brand-nav__link:after,
  .js-has-subnav .corporate-nav__link:after,
  .js-has-subnav .brand-nav__list-link:after,
  .js-has-subnav .corporate-nav__list-link:after {
    display: none;
  }
}
.menu-toggle-right .menu-toggle-btn.active div {
  margin-left: 0.4rem;
}
.menu-toggle-btn {
  width: 3rem;
  height: 3rem;
  padding: 3.3rem 2.7rem;
}
.menu-toggle-btn div {
  width: 22px;
  height: 3px;
  margin-left: -10px;
}
@media (min-width: 768px) {
  .menu-toggle-btn div {
    width: 35px;
  }
}
.menu-toggle-btn div:nth-child(1) {
  margin-top: -10px;
}
.menu-toggle-btn div:nth-child(3) {
  margin-top: 10px;
}
.menu-toggle-btn.active div:nth-child(1) {
  transform: translate(-8px, 10px) rotate(45deg);
  -webkit-transform: translate(-8px, 10px) rotate(45deg);
}
.menu-toggle-btn.active div:nth-child(3) {
  transform: translate(-8px, -10px) rotate(-45deg);
  -webkit-transform: translate(-8px, -10px) rotate(-45deg);
}
.brand-nav__logo-link,
.corporate-nav__logo-link {
  width: 100%;
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .brand-nav__logo-link,
  .corporate-nav__logo-link {
    justify-content: center;
  }
}
@media (min-width: 950px) {
  .brand-nav__logo-link,
  .corporate-nav__logo-link {
    display: block;
  }
}
.is-logged-in .brand-nav__logo-link,
.is-logged-in .corporate-nav__logo-link {
  justify-content: center;
}
.top-navigation.is-showing-left-menu .brand-nav__logo,
.top-navigation.is-showing-left-menu .corporate-nav__logo {
  display: none;
}
.top-navigation.is-showing-right-menu .brand-nav__logo,
.top-navigation.is-showing-right-menu .corporate-nav__logo {
  display: none;
}
.brand-nav__logo-cube,
.corporate-nav__logo-cube {
  width: 2rem;
  height: 2rem;
}
@media (max-width: 375px) {
  .brand-nav__logo-cube,
  .corporate-nav__logo-cube {
    width: 1.6rem;
    height: 1.6rem;
  }
}
.brand-nav__item,
.corporate-nav__item {
  font-size: 1.6rem;
}
.brand-nav .user-bar__item,
.corporate-nav .user-bar__item {
  font-size: 1.1rem;
  height: 6.7rem;
  line-height: 9.1rem;
}
.brand-nav .user-bar .login-btn.mobile-only,
.corporate-nav .user-bar .login-btn.mobile-only {
  font-size: 1.1rem;
  line-height: 9.1rem;
}
@media (min-width: 950px) {
  .brand-nav .user-bar .login-btn.mobile-only,
  .corporate-nav .user-bar .login-btn.mobile-only {
    font-size: 1.8rem;
  }
}
.dli .brand-nav .user-bar .profile-btn.mobile-only,
.dli .corporate-nav .user-bar .profile-btn.mobile-only,
.dlo .brand-nav .user-bar .profile-btn.mobile-only,
.dlo .corporate-nav .user-bar .profile-btn.mobile-only {
  height: 4.4rem;
  width: 4.4rem;
  top: 1rem;
}
.dli .brand-nav .user-bar .profile-btn.mobile-only:before,
.dli .corporate-nav .user-bar .profile-btn.mobile-only:before,
.dlo .brand-nav .user-bar .profile-btn.mobile-only:before,
.dlo .corporate-nav .user-bar .profile-btn.mobile-only:before {
  width: 2.3rem;
  height: 2.3rem;
  margin: 0;
}
.top-navigation.is-showing-right-menu .dli .brand-nav .user-bar .profile-btn.mobile-only,
.top-navigation.is-showing-right-menu .dli .corporate-nav .user-bar .profile-btn.mobile-only,
.top-navigation.is-showing-right-menu .dlo .brand-nav .user-bar .profile-btn.mobile-only,
.top-navigation.is-showing-right-menu .dlo .corporate-nav .user-bar .profile-btn.mobile-only {
  display: none;
}
.brand-nav .games-mobile-nav .corporate-nav__list-link,
.corporate-nav .games-mobile-nav .corporate-nav__list-link {
  font-size: 2rem;
  padding: 2rem 5rem;
}
.brand-nav .games-mobile-nav .sub-navigation .games-list-item,
.corporate-nav .games-mobile-nav .sub-navigation .games-list-item {
  height: 4.5rem;
}
.brand-nav .games-mobile-nav .sub-navigation .header__cta-component__description,
.corporate-nav .games-mobile-nav .sub-navigation .header__cta-component__description {
  font-size: 1.8rem;
}
.brand-nav .games-mobile-nav .sub-navigation .header__cta-component__cta-home,
.corporate-nav .games-mobile-nav .sub-navigation .header__cta-component__cta-home {
  font-size: 2rem;
  padding: 2rem 5rem;
}
.brand-nav .mobile-nav-right .profile-btn.mobile-only,
.corporate-nav .mobile-nav-right .profile-btn.mobile-only {
  height: 4.4rem;
  width: 4.4rem;
  top: 1.4rem;
}
.brand-nav .mobile-nav-right .profile-btn.mobile-only:before,
.corporate-nav .mobile-nav-right .profile-btn.mobile-only:before {
  width: 2.3rem;
  height: 2.3rem;
  margin: 0;
}
@media (min-width: 768px) and (max-width: 949px) {
  .brand-nav__navigation.mobile-nav-right,
  .corporate-nav__navigation.mobile-nav-right {
    transform: translateX(100vw);
    -webkit-transform: translateX(100vw);
  }
  .brand-nav__navigation.mobile-nav-right .mega-menu__account-details__title,
  .corporate-nav__navigation.mobile-nav-right .mega-menu__account-details__title {
    margin-bottom: 0;
  }
  .brand-nav__navigation.mobile-nav-right .mega-menu__account-details__logout-btn,
  .corporate-nav__navigation.mobile-nav-right .mega-menu__account-details__logout-btn {
    font-size: inherit;
    padding-top: 0.2rem;
  }
  .is-tablet-detected .brand-nav__navigation.mobile-nav-right .mega-menu__account-details__logout-btn,
  .is-tablet-detected .corporate-nav__navigation.mobile-nav-right .mega-menu__account-details__logout-btn {
    padding-top: 0.5rem;
  }
  .brand-nav__navigation.mobile-nav-right .mega-menu__account-details__button,
  .corporate-nav__navigation.mobile-nav-right .mega-menu__account-details__button,
  .brand-nav__navigation.mobile-nav-right .mega-menu__account-details__details-btn,
  .corporate-nav__navigation.mobile-nav-right .mega-menu__account-details__details-btn {
    font-size: inherit;
  }
  .brand-nav__navigation.mobile-nav-right .navigation-list .navigation-item .navigation-item-link,
  .corporate-nav__navigation.mobile-nav-right .navigation-list .navigation-item .navigation-item-link {
    font-size: inherit;
  }
  .brand-nav .mobile-nav-right .profile-btn.mobile-only,
  .corporate-nav .mobile-nav-right .profile-btn.mobile-only {
    display: none !important;
  }
  .is-tablet-detected .brand-nav__container .pam-return-to-site-close,
  .is-tablet-detected .corporate-nav__container .pam-return-to-site-close {
    left: 0 !important;
    width: 6vw !important;
    height: 6vw !important;
    top: 2vw !important;
  }
  .header.is-showing-right-menu {
    padding: 0;
    transform: translateX(-84.6vw) !important;
  }
  .header.is-showing-right-menu .menu-toggle-right {
    right: 0;
    transform: none !important;
  }
  .is-tablet-detected .header.is-showing-right-menu {
    transform: translateX(-50vw) !important;
  }
  .header.is-showing-left-menu {
    padding: 0;
    transform: translateX(84.6vw) !important;
  }
  .is-tablet-detected .header.is-showing-left-menu {
    transform: translateX(50vw) !important;
  }
  .header {
    margin-top: -1px;
  }
}
@media screen and (-ms-high-contrast: active) and (max-device-width: 30em), (-ms-high-contrast: none) and (max-device-width: 30em) {
  .mobile-navigation-header__logo-img {
    max-width: 30vw;
  }
  .header.is-showing-left-menu .mobile-nav-left {
    transform: translateX(0px);
  }
  .header .brand-nav {
    transition: transform 0.4s ease;
  }
  .header .brand-nav__logo {
    text-align: center;
    z-index: 0;
  }
  .header .brand-nav__logo-img {
    max-width: 30vw;
  }
  .header .brand-nav__logo a {
    display: inline-block;
    z-index: 0;
  }
}
body.is-context-neutral .user-bar {
  display: none !important;
}
.header.top-navigation .user-bar {
  float: right;
  display: inline-block;
}
@media all and (min-width: 950px) {
  .is-logged-in .header.top-navigation .user-bar .has-shown-subnav .mega-menu {
    transform: translateY(100%) scaleY(1);
    z-index: 1;
  }
}
.header.top-navigation .user-bar__navigation {
  width: auto;
  height: 100%;
  display: inline-block;
}
.header.top-navigation .user-bar__list {
  margin: 0;
  padding: 0;
  max-height: 100%;
}
.header.top-navigation .user-bar .choose-account {
  display: none;
}
@media all and (min-width: 950px) {
  .header.top-navigation .accountmenu .mobile-only {
    display: none;
  }
}
.header.top-navigation .accountmenu__group {
  display: inline-block;
}
.header.top-navigation .accountmenu__item {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 1.25rem 1rem 0.9rem;
  line-height: 1.3;
  transition: 0.2s;
  color: #fff;
  text-decoration: none;
}
.header.top-navigation .accountmenu a.accountmenu__item {
  cursor: pointer;
}
.header.top-navigation .accountmenu .choose-account-btn {
  cursor: pointer;
  display: none;
}
@media all and (max-width: 949px) {
  .header.top-navigation .accountmenu .choose-account-btn {
    float: right;
    font-size: 3vw;
    padding-top: 6vw;
    padding-right: 3.5vw;
  }
}
.header.top-navigation .accountmenu .info-bar-item--username {
  display: block;
  font-size: 1.2rem;
  padding: 0 1rem 0;
}
.header.top-navigation .accountmenu .info-bar-item--account {
  display: none;
  font-size: 1.2rem;
  padding: 0.45rem 1rem 0;
}
.header.top-navigation .accountmenu .info-bar-item--ballance {
  position: relative;
}
.header.top-navigation .accountmenu .info-bar-item--ballance:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 1px;
  height: 2.5rem;
  background: #fff;
  opacity: 0.1;
  display: none;
}
.is-logged-in .header.top-navigation .accountmenu .info-bar-item--ballance:before {
  display: block;
}
.header.top-navigation .accountmenu .signup-btn {
  position: relative;
  background-color: #feb701;
  color: black;
  border-radius: 0.3rem;
  margin-right: 1rem;
  padding: 0.5rem 1rem;
  margin-top: 0.6rem;
}
.header.top-navigation .accountmenu .login-btn {
  color: white;
  border: 0.2rem solid white;
  border-radius: 0.3rem;
  padding: 0.3rem 0.8rem;
  margin-top: 0.6rem;
}
.header.top-navigation .accountmenu .profile-btn {
  position: relative;
  top: 0.6rem;
  float: left;
  height: 2.8rem;
  width: 2.8rem;
  padding: 0;
  display: none;
  background: #fff;
  border-radius: 50%;
  padding-top: 1rem;
}
.header.top-navigation .accountmenu .profile-btn.mobile-only {
  display: none;
}
.header.top-navigation .accountmenu .profile-btn:before {
  content: ' ';
  cursor: pointer;
  width: 1.3rem;
  height: 1.6rem;
  position: absolute;
  top: 50%;
  left: 49.5%;
  transform: translate(-50%, -50%);
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
}
.is-logged-in .header.top-navigation .profile-btn {
  display: inline-block;
}
.is-logged-in .header.top-navigation .login-btn,
.is-logged-in .header.top-navigation .signup-btn,
.is-logged-in .header.top-navigation .choose-account-btn {
  display: none;
}
.mobile-user-info .account-name {
  font-weight: bold;
  display: none;
}
.dli .mobile-user-info .account-name.dli {
  color: #263863;
  display: inline-block;
}
.dlo .mobile-user-info .account-name.dlo {
  color: #8f0b0f;
  display: block;
}
.mobile-user-info {
  clear: both;
  width: 100%;
  display: none;
  background-color: #fff;
  padding: 0 0.8rem;
  position: relative;
}
@media all and (min-width: 768px) and (max-width: 949px) {
  .mobile-user-info {
    padding: 0 1rem;
  }
}
.is-logged-in .mobile-user-info {
  display: block;
}
@media all and (min-width: 950px) {
  .is-logged-in .mobile-user-info {
    display: none;
  }
}
body.is-context-neutral .mobile-user-info {
  display: none !important;
}
.mobile-user-info:after {
  display: table;
  content: ' ';
  clear: both;
}
.mobile-user-info .js-balance-user {
  font-weight: bold;
  text-align: right;
  white-space: nowrap;
}
.mobile-user-info__center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.mobile-user-info__left,
.mobile-user-info__right,
.mobile-user-info__center {
  display: inline-block;
  vertical-align: middle;
}
.mobile-user-info__left p,
.mobile-user-info__right p,
.mobile-user-info__center p,
.mobile-user-info__left a,
.mobile-user-info__right a,
.mobile-user-info__center a {
  margin: 0;
  padding: 0.4rem 0;
  color: #000;
  font-size: 1rem;
  line-height: 1;
}
@media all and (min-width: 768px) and (max-width: 949px) {
  .mobile-user-info__left p,
  .mobile-user-info__right p,
  .mobile-user-info__center p,
  .mobile-user-info__left a,
  .mobile-user-info__right a,
  .mobile-user-info__center a {
    font-size: 14px;
  }
}
.mobile-user-info__left {
  float: left;
}
@media all and (max-width: 767px) {
  .mobile-user-info__left {
    line-height: 0.8;
  }
}
.mobile-user-info__right {
  float: right;
}
body.region-retail-account.is-logged-in .user-bar {
  display: none !important;
}
body.region-retail-account .create-btn {
  display: none;
}
.is-logged-in.accountmenu .choose-account-btn {
  display: none;
}
.top-navigation-theme-playeraccountmanagement .brand-nav__logo .pam-return-to-site-back {
  display: flex;
}
@media all and (max-width: 950px) {
  .top-navigation-theme-playeraccountmanagement .brand-nav__logo .pam-return-to-site-back {
    display: none;
  }
}
.top-navigation-theme-playeraccountmanagement .brand-nav__logo-cube {
  display: none;
}
.top-navigation-theme-playeraccountmanagement .brand-nav__logo .brand-nav__logo-img,
.top-navigation-theme-playeraccountmanagement .brand-nav__container .pam-return-to-site-close .brand-nav__logo-img {
  display: none;
}
@media all and (max-width: 950px) {
  .top-navigation-theme-playeraccountmanagement .brand-nav__logo .brand-nav__logo-img,
  .top-navigation-theme-playeraccountmanagement .brand-nav__container .pam-return-to-site-close .brand-nav__logo-img {
    display: inline-block;
    padding-left: 0;
  }
}
.top-navigation-theme-playeraccountmanagement .brand-nav__container .pam-return-to-site-close {
  display: block;
}
@media all and (max-width: 950px) {
  .top-navigation-theme-playeraccountmanagement .brand-nav__container .menu-toggle-left {
    display: none;
  }
  .top-navigation-theme-playeraccountmanagement .brand-nav__container .pam-return-to-site-close {
    left: 0vw;
    height: 4.4rem;
    width: 4.4rem;
    top: 1rem;
  }
}
.dli .top-navigation-theme-playeraccountmanagement .corporate-nav {
  background-color: #1c2d56;
}
.dli .top-navigation-theme-playeraccountmanagement .corporate-nav__list-item:not(.js-has-subnav) .corporate-nav__list-link:hover {
  color: #fff;
  opacity: 0.6;
}
.dli .top-navigation-theme-playeraccountmanagement .menu-toggle-right {
  background-color: #263863;
}
.dli .top-navigation-theme-playeraccountmanagement .brand-nav {
  background-color: #263863;
}
.dli .top-navigation-theme-playeraccountmanagement .brand-nav__item:not(.js-has-subnav) .brand-nav__link:hover {
  color: #fff;
  opacity: 0.6;
}
.dli .top-navigation-theme-playeraccountmanagement .brand-nav__logo-text.dlo {
  display: none;
}
.dli .top-navigation-theme-playeraccountmanagement .brand-nav .mobile-navigation-header {
  background-color: #263863;
}
.dli.is-logged-in .accountmenu__item.dli {
  display: block !important;
}
.dli .header .user-bar .profile-btn:before,
.dli .header .mobile-nav-right .profile-btn:before {
  background: url('/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons/icon_pam_user-dli.svg') center center no-repeat;
}
.dlo .top-navigation-theme-playeraccountmanagement .corporate-nav {
  background-color: #8f0b0f;
}
.dlo .top-navigation-theme-playeraccountmanagement .corporate-nav__list-item:not(.js-has-subnav) .corporate-nav__list-link:hover {
  color: #fff;
  opacity: 0.6;
}
.dlo .top-navigation-theme-playeraccountmanagement .menu-toggle-right {
  background-color: #9a0000;
}
.dlo .top-navigation-theme-playeraccountmanagement .brand-nav {
  background-color: #9a0000;
}
.dlo .top-navigation-theme-playeraccountmanagement .brand-nav__item:not(.js-has-subnav) .brand-nav__link:hover {
  color: #fff;
  opacity: 0.6;
}
.dlo .top-navigation-theme-playeraccountmanagement .brand-nav__logo-text.dli {
  display: none;
}
.dlo .top-navigation-theme-playeraccountmanagement .brand-nav .mobile-navigation-header {
  background-color: #9a0000;
}
.dlo.is-logged-in .accountmenu__item.dlo {
  display: block !important;
}
.dlo .header .user-bar .profile-btn:before,
.dlo .header .mobile-nav-right .profile-btn:before {
  background: url('/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons/icon_pam_user-dlo.svg') center center no-repeat;
}
.mega-menu__account {
  padding-left: 2rem;
}
.dli .mega-menu__account-name.dlo,
.dli .mega-menu__account-meta.dlo,
.dli .mega-menu__account-details__left.dlo {
  display: none;
}
.dlo .mega-menu__account-name.dli,
.dlo .mega-menu__account-meta.dli,
.dlo .mega-menu__account-details__left.dli {
  display: none;
}
.mega-menu__account-name {
  margin-bottom: 0;
}
.mega-menu__account-name.dli {
  color: #1c2d56;
}
.mega-menu__account-name.dlo {
  color: #9a0000;
}
.mega-menu__account-meta {
  margin: 0;
  font-size: 1.2rem;
}
.mega-menu__inner {
  max-width: 1440px;
  margin: 0 auto;
}
.mega-menu .sub-sub-navigation__section-link {
  font-weight: bold;
}
@media all and (max-width: 949px) {
  .mega-menu .navigation-list {
    display: block !important;
  }
  .mega-menu .sub-sub-navigation {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .mega-menu .sub-sub-sub-navigation {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    background: #e3e3e3 !important;
  }
  .mega-menu .sub-sub-sub-navigation li a {
    border-bottom: none !important;
    font-weight: 100;
  }
}
@media all and (min-width: 950px) {
  .mega-menu {
    padding: 0 !important;
    width: 100% !important;
    min-width: 950px;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1);
  }
  .mega-menu .navigation-list {
    padding-left: 0 !important;
  }
  .mega-menu__inner {
    padding: 0 0 15px 0 !important;
    width: 100% !important;
    overflow: hidden;
  }
  .mega-menu__inner:after {
    content: "";
    display: table !important;
    clear: both;
  }
}
@media all and (min-width: 950px) {
  .mega-menu__account-details {
    margin-top: -55px;
    padding-top: 0 !important;
  }
}
@media all and (min-width: 950px) {
  .mega-menu__section {
    float: left;
    padding-top: 22px !important;
    padding-right: 20px !important;
    padding-bottom: 10px;
    position: relative;
    overflow: visible !important;
    box-sizing: border-box;
    list-style: none;
  }
  html[class] .mega-menu__section {
    padding-left: 2rem !important;
  }
  .mega-menu__section:last-child:after {
    display: none;
  }
  .mega-menu__section.navigation-list.account-section:first-child:after {
    display: none;
  }
  .mega-menu__section:first-child:nth-last-child(1) {
    width: 100%;
  }
  .mega-menu__section:first-child:nth-last-child(2),
  .mega-menu__section:first-child:nth-last-child(2) ~ .mega-menu__section {
    width: 50%;
  }
  .mega-menu__section:first-child:nth-last-child(3),
  .mega-menu__section:first-child:nth-last-child(3) ~ .mega-menu__section {
    width: 33.3333%;
  }
  .mega-menu__section:first-child:nth-last-child(4),
  .mega-menu__section:first-child:nth-last-child(4) ~ .mega-menu__section {
    width: 25%;
  }
  .mega-menu__section:first-child:nth-last-child(5),
  .mega-menu__section:first-child:nth-last-child(5) ~ .mega-menu__section {
    width: 25%;
  }
}
@media all and (min-width: 950px) {
  .mega-menu__section__title {
    color: #323232;
    font-size: 16px;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .mega-menu__section__title > a,
  .mega-menu__section__title__inner {
    display: block;
    color: inherit;
    padding: 0;
    margin-bottom: 15px;
    cursor: default;
    outline: none;
    text-decoration: none;
    font-weight: 500;
  }
}
.header .games-frontpage {
  max-width: 144rem;
}
.header .games-frontpage .games-frontpage__section {
  display: inline-flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 0.8rem;
}
.header .games-frontpage .games-frontpage__section .games-frontpage__section-header {
  margin-bottom: 0.5rem;
  padding: 0 0.5rem;
}
.header .games-frontpage .games-frontpage__section .games-frontpage__section-sublist {
  font-size: 1.2rem;
  font-weight: normal;
  font-style: italic;
  color: #6f6f6f;
  margin: 1.1rem 0;
  padding: 0 0.5rem;
}
.header .games-frontpage .games-frontpage__section .games-frontpage__section-create-account {
  font-weight: 700;
  margin-bottom: 0.5rem;
  padding: 0 0.5rem;
}
.header .games-frontpage .games-frontpage__section .games-frontpage__section-create-account .games-frontpage__section-create-account-text,
.header .games-frontpage .games-frontpage__section .games-frontpage__section-create-account .games-frontpage__section-create-account-cta {
  display: inline-block;
}
.header .games-frontpage .games-frontpage__section .games-frontpage__section-create-account .games-frontpage__section-create-account-cta {
  color: #2f80ed;
}
.header .games-frontpage .games-frontpage__section .games-frontpage__section-bottom-text {
  font-size: 1.1rem;
  padding: 0 0.5rem;
}
.header .games-frontpage .games-frontpage__section .games-frontpage__section-sikkert-spil {
  display: none;
}
.header .games-frontpage .games-frontpage__section--1 {
  width: calc(50% - 1.5rem);
  background-color: rgba(15, 64, 150, 0.05);
  margin: 1rem;
}
.header .games-frontpage .games-frontpage__section--1 .games-frontpage__section-header {
  color: #0f4096;
}
.header .games-frontpage .games-frontpage__section--2 {
  width: calc(50% - 1.5rem);
  background-color: rgba(194, 22, 23, 0.05);
  margin: 1rem 1rem 1rem 0;
}
.header .games-frontpage .games-frontpage__section--2 .games-frontpage__section-header {
  color: #c21617;
}
.header .games-frontpage .games-frontpage__section--3 {
  width: calc(100% - 2rem);
  background-color: rgba(1, 75, 50, 0.05);
  margin: 0 1rem 1rem 1rem;
  flex-direction: row;
  flex-wrap: nowrap;
}
.header .games-frontpage .games-frontpage__section--3 .games-frontpage__section-header {
  display: none;
}
.header .games-frontpage .games-frontpage__section--3 .games-frontpage__section-sublist {
  display: none;
}
.header .games-frontpage .games-frontpage__section--3 .games-frontpage__section-create-account {
  display: none;
}
.header .games-frontpage .games-frontpage__section--3 .games-frontpage__section-bottom-text {
  display: none;
}
.header .games-frontpage .games-frontpage__section--3 .games-frontpage__section-sikkert-spil {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0.5rem 1rem;
  font-size: 1.4rem;
}
.header .games-frontpage .games-frontpage__section--3 .games-frontpage__section-sikkert-spil-text {
  color: #014c34;
}
.header .games-frontpage .games-frontpage__section--3 .games-frontpage__section-sikkert-spil-cta {
  font-weight: 700;
  color: #2f80ed;
}
.header .games-frontpage .games-frontpage__list {
  display: inline-flex;
  flex-wrap: wrap;
  padding: 0;
}
.header .games-frontpage .games-frontpage__list .games-frontpage__list-item {
  list-style: none;
  display: block;
  border-radius: 0.5rem;
  margin: 0.5rem;
  width: calc(20% - 1rem);
  height: 3.5rem;
  transition: transform 0.2s ease;
}
.header .games-frontpage .games-frontpage__list .games-frontpage__list-item:hover {
  transform: scale(1.1);
}
.header .games-frontpage .games-frontpage__list .games-frontpage__list-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0.9rem 1rem;
  position: relative;
}
.header .games-frontpage .games-frontpage__list .games-frontpage__list-item a:focus {
  outline: none;
}
.header .games-frontpage .games-frontpage__list .games-frontpage__list-item.altellerintet a {
  padding: 0 1rem 0.5rem 1rem;
}
.header .games-frontpage .games-frontpage__list .games-frontpage__list-item.bingo a {
  padding: 0.5rem 1rem;
}
.header .games-frontpage .games-frontpage__list .games-frontpage__list-item.virtuel a,
.header .games-frontpage .games-frontpage__list .games-frontpage__list-item.Virtuel a {
  padding: 0 1rem 0.9rem;
}
.header .games-frontpage .games-frontpage__list .games-frontpage__list-item img {
  max-height: 100%;
  width: 100%;
  object-fit: contain;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .header .games-frontpage .games-frontpage__list .games-frontpage__list-item img {
    height: 3rem;
  }
}
.header .games {
  display: flex;
  flex-flow: column nowrap;
}
@media (min-width: 950px) {
  .header .games {
    flex-direction: row;
  }
}
.header .games__group {
  position: relative;
  padding: 1rem;
  width: 100%;
}
@media all and (min-width: 950px) {
  .header .games__group {
    width: var(--games__group_width-desktop, auto);
  }
}
.header .games__group--xxxlarge {
  --games__group_width-desktop: 100%;
}
.header .games__group--xxlarge {
  --games__group_width-desktop: 50%;
}
.header .games__group--xlarge {
  --games__group_width-desktop: 40%;
}
.header .games__group--large {
  --games__group_width-desktop: 30%;
}
.header .games__group--medium {
  --games__group_width-desktop: 20%;
}
.header .games__group--4sec-3col {
  --games__list-item--manually-sized: 51%;
}
.header .games__group--3sec-3col {
  --games__list-item--manually-sized: 69%;
}
.header .games__group--3sec-5col {
  --games__list-item--manually-sized: 52%;
}
.header .games__group:nth-child(n+2):before {
  content: "";
  display: block;
  width: 100%;
  height: 0.1rem;
  background: #dbdbdb;
  position: absolute;
  left: -0.1rem;
  bottom: 0;
  top: 0;
}
@media (min-width: 950px) {
  .header .games__group:nth-child(n+2):before {
    width: 0.1rem;
    height: 100%;
  }
}
.header .games__headline {
  font-family: var(--font-family-danskespil);
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 1.2;
  font-style: italic;
  text-align: left;
  color: #6f6f6f;
  margin: 0.7rem 0;
  padding: 0 0.5rem;
}
@media all and (min-width: 768px) {
  .header .games__headline {
    min-height: calc(1.2rem * 1.2 * 2);
  }
}
.header .games__list {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin: 0;
  padding: 0;
  --games__list-item_width: 33.333%;
}
@media (min-width: 950px) {
  .header .games__list {
    flex-direction: row;
  }
  .header .games__list--wide {
    --games__list-item_width: 20%;
  }
  .header .games__list--narrow {
    --games__list-item_width: 33.333%;
  }
}
.header .games__list-item {
  list-style: none;
  margin: 0;
  padding: 0.5rem;
  width: var(--games__list-item_width, auto);
  transition: transform 0.2s ease;
  -webkit-backface-visibility: hidden;
}
.header .games__list-item:hover {
  transform: scale(1.1);
}
@media (min-width: 950px) {
  .header .games__list-item.sikkertspil,
  .header .games__list-item.klasselotteriet {
    width: var(--games__list-item--manually-sized, 55%);
  }
}
.header .games__list-item.virtuel,
.header .games__list-item.Virtuel {
  --games__list-item-link_padding: 0 1rem 0.9rem;
}
.header .games__list-item-link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  height: 5rem;
  padding: var(--games__list-item-link_padding, 0.9rem 1rem);
  position: relative;
  border-radius: 0.5rem;
}
.header .games__list-item-link:focus {
  outline: none;
}
.header .games__list-item-image {
  max-height: 100%;
  width: 100%;
  object-fit: contain;
  font-size: 1rem;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .header .games__list-item-image {
    height: 3rem;
  }
}
@media (min-width: 768px) {
  .header .games[data-games-section-count="1"] .games__list-item {
    width: 10%;
  }
}
@media all and (min-width: 1440px) {
  .header .games[data-games-section-count="1"] .games__list-item {
    margin-bottom: 3.1rem;
  }
}
.header.has-cta-component .no-readmore-link {
  width: 100%;
}
@media all and (min-width: 950px) {
  .header.has-cta-component .no-readmore-link {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}
.header__cta-component {
  font-size: 12px;
  font-size: 1.2rem;
  text-align: center;
}
.games__group .header__cta-component {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
}
@media (min-width: 950px) {
  .games__group .header__cta-component {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}
.header__cta-component__description {
  padding: 0 2em;
  text-align: center;
  color: #000000;
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: 600;
  font-style: normal;
  margin: 0 auto 2rem;
  white-space: normal;
}
.header__cta-component__read-more-link {
  display: block;
  text-decoration: underline;
  color: #000000;
}
.header__cta-component__cta-home {
  background: #156a5f;
  color: #fff;
  font-size: 10px;
  font-size: 1rem;
  display: inline-block;
  padding: 13px 16px 16px 16px;
  margin-bottom: 8px;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
  /*&:active {
    background: @color-white-100;
    color: @color-gray-100;
  }

  &--inactive {
    background: @color-gray-400;
    color: @color-gray-300;
  }

  &--medium {
  }

  &-small {
  }

  @media screen and (min-width: @medium) {
    .font-size(14);
  }*/
}
.header__cta-component__cta-home:hover {
  background: #004b32;
  text-decoration: none;
}
@media all and (min-width: 950px) {
  .simple-menu {
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1);
    padding-left: 20px;
    box-sizing: border-box !important;
  }
  html[class] .simple-menu .sub-navigation-inner {
    max-width: 1440px;
    margin: 0 auto;
    box-sizing: border-box !important;
  }
  html[class] .simple-menu .navigation-list {
    padding-left: 0;
  }
}
@media all and (min-width: 950px) and (max-width: 1439px) {
  html[class] .simple-menu .sub-navigation-inner {
    padding-left: 172px;
  }
}
@media all and (min-width: 950px) and (min-width: 1440px) {
  html[class] .simple-menu .sub-navigation-inner {
    padding-left: 166px;
  }
}
.mega-menu__account-details {
  display: block;
  color: #323232;
}
@media all and (max-width: 949px) {
  .mega-menu__account-details {
    display: none;
  }
}
.mega-menu__account-details__title__inner {
  margin-bottom: 0;
  font-weight: 300;
}
.mega-menu__account-details__logout-btn {
  float: right;
  color: inherit;
  font-weight: 300;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
  position: relative;
  z-index: 0;
  text-decoration: none;
  border-bottom: 1px solid black;
  border-color: lightgray;
  padding-top: 0.4rem;
}
.mega-menu__account-details__logout-btn:hover {
  text-decoration: none;
}
.mega-menu__account-details__logout-btn:hover:after {
  background: #323232;
}
.mega-menu__account-details__details-btn {
  float: right;
  color: inherit;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 300;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
  position: relative;
  z-index: 0;
  text-decoration: none;
  border-bottom: 1px solid black;
  border-color: lightgray;
}
.mega-menu__account-details__details-btn:hover {
  text-decoration: none;
}
.mega-menu__account-details__details-btn:hover:after {
  background: #323232;
}
.mega-menu__account-details__row {
  margin-top: 0.4rem;
}
.mega-menu__account-details__row--button-container {
  clear: both;
}
.mega-menu__account-details__row:after {
  content: "";
  display: table;
  clear: both;
}
.mega-menu__account-details--dli-only {
  display: none;
}
.mega-menu__account-details__row-buttons {
  display: flex;
  width: 100%;
}
.mega-menu__account-details__divider {
  border: none;
  border-top: 1px solid #cacaca;
  margin: 2rem 0;
}
.mega-menu__account-details__small-text {
  font-size: 1.3rem;
}
.mega-menu__account-details__large-text {
  font-size: 1.6rem;
  font-weight: 600;
}
.mega-menu__account-details__larger-text {
  font-size: 2rem;
  font-weight: bold;
}
.is-tablet-detected .mega-menu__account-details__larger-text {
  font-size: 2rem;
}
.mega-menu__account-details--margin-bottom {
  margin-bottom: 1.3rem;
}
.mega-menu__account-details__left {
  float: left;
}
.mega-menu__account-details__right {
  float: right;
}
.mega-menu__account-details__button {
  display: inline-block;
  color: #323232;
  background: #fcb40f;
  border-radius: 0.4rem;
  font-size: 1.6rem;
  font-weight: bold;
  padding: 0.8em 1em;
  line-height: 1.2;
  text-decoration: none;
  margin: 2.5rem 2rem 0 0;
  transition: 0.3s;
}
.mega-menu__account-details__button:last-child {
  margin-right: 0;
}
.mega-menu__account-details__button:hover {
  text-decoration: none;
  background: #fdc137;
}
.mega-menu .mega-menu__image-list {
  display: flex;
  flex-wrap: wrap;
}
@media all and (max-width: 950px) {
  .mega-menu .mega-menu__image-list {
    flex-direction: column;
  }
}
.mega-menu .mega-menu__image-list__item {
  color: #323232;
  padding: 1.2rem 5rem;
  font-size: 21px;
  display: inline-block;
}
@media all and (min-width: 950px) {
  .mega-menu .mega-menu__image-list__item {
    padding: 0 1.5rem 2.2rem 1.5rem;
  }
}
.mega-menu .mega-menu__image-list__item__link {
  font-weight: 100;
  color: inherit;
  font-size: inherit;
  display: block;
  width: 100%;
}
html[class] .mega-menu .mega-menu__image-list__item__link {
  padding: 0 0 5px;
}
.mega-menu .mega-menu__image-list__item__link img {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 2px;
  transition: 0.25s ease-out;
}
.mega-menu .mega-menu__image-list__item__link p {
  margin: 0;
  font-size: 12px;
  line-height: 1.5;
  overflow: hidden;
  transition: 0.25s ease-out;
}
@media all and (max-width: 950px) {
  .mega-menu .mega-menu__image-list__item__link p {
    font-size: 1.4rem;
  }
}
.mega-menu .mega-menu__image-list__item__link:hover {
  opacity: 1;
}
.mega-menu .mega-menu__image-list__item__link:hover img {
  transform: scale(1.1);
}
@media all and (min-width: 950px) {
  .mega-menu .mega-menu__image-list__item {
    width: 33.3%;
  }
}
@media all and (min-width: 950px) {
  .mega-menu .mega-menu__image-list__item {
    width: 29%;
  }
  .mega-menu .mega-menu__section:first-child:last-child .mega-menu__image-list__item {
    max-width: 150px;
  }
}
@media all and (max-width: 950px) {
  .mega-menu .mega-menu__image-list__item__link {
    color: #000;
    font-weight: 600;
    display: inline-block;
  }
  .mega-menu .mega-menu__image-list__item__link p {
    display: inline;
  }
  .mega-menu .mega-menu__image-list__item__link img {
    display: none;
  }
}
@media all and (min-width: 950px) {
  .mega-menu .navigation-list {
    padding-left: 0 !important;
  }
  .mega-menu .mega-menu__link-list {
    display: block !important;
  }
  .mega-menu .mega-menu__link-list__item {
    display: block;
    float: none;
    color: #323232;
    font-size: 1.4rem;
  }
  .mega-menu .mega-menu__link-list__item__link {
    display: inline-block;
    font-weight: 400;
    color: inherit;
    font-size: inherit;
    padding: 0 0 10px 0 !important;
    text-decoration: none;
  }
}
@media all and (max-width: 949px) {
  .mega-menu .sub-sub-navigation__section-link {
    font-size: 1.6rem;
    width: 100%;
    padding: 1.6rem;
    padding-left: 3.2rem;
    color: #000;
    font-weight: 600 !important;
    display: inline-block;
  }
  .mega-menu .mega-menu__link-list {
    max-height: none !important;
    display: block;
    width: 100%;
  }
  .mega-menu .mega-menu__link-list__item {
    font-size: 1.6rem;
    display: block;
    width: 100%;
  }
  .mega-menu .mega-menu__link-list__item__link {
    padding: 1.6rem;
    padding-left: 4.8rem;
    color: #000;
    font-weight: 600 !important;
    display: inline-block;
  }
}
.mega-menu__campaign {
  display: none;
}
@media all and (min-width: 950px) {
  .mega-menu__campaign {
    display: block;
  }
  .mega-menu__campaign__link {
    display: inline-block;
    text-decoration: none;
  }
  .mega-menu__campaign__link__image {
    width: auto;
    max-width: 100%;
    height: auto;
  }
  .mega-menu__campaign__link__text {
    display: block;
    font-size: 16px;
  }
  .mega-menu__campaign__cta-button {
    font-size: 16px;
    line-height: 1.2;
    padding: 0.2em 1em 0.6em;
    border-radius: 4px;
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    transition: 0.3s;
    margin-top: 12px;
  }
  .mega-menu__campaign__cta-button:hover {
    text-decoration: none;
  }
}
.nav-game-overview {
  width: 100%;
}
.nav-game-overview:before,
.nav-game-overview:after {
  content: "";
  display: table;
}
.nav-game-overview:after {
  clear: both;
}
@media all and (min-width: 950px) {
  .nav-game-overview {
    padding-left: 0 !important;
    margin-left: auto !important;
    margin-right: auto;
    display: block;
    max-width: 1440px;
    padding-top: 2.2rem;
    padding-bottom: 1.7rem !important;
    min-width: 950px;
  }
}
@media all and (min-width: 950px) {
  .nav-game-overview:after {
    content: "";
    display: table;
    clear: both;
  }
}
.nav-game-overview__item {
  float: left;
  text-align: center;
  overflow: hidden;
  position: relative;
}
@media all and (min-width: 950px) {
  .nav-game-overview__item {
    width: 16.6666%;
    min-width: 170px;
    height: 150px;
  }
}
.nav-game-overview__link {
  text-align: center;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1;
  outline: none;
  color: #000;
  padding: 15px 25px;
  display: inline-block;
}
@media all and (min-width: 950px) {
  .nav-game-overview__link:hover {
    opacity: 1 !important;
  }
  .nav-game-overview__link:hover img {
    transform: scale(1.1);
  }
}
.nav-game-overview__image {
  position: relative;
  transition: opacity 0.5s linear;
}
@media all and (min-width: 950px) {
  .nav-game-overview__image {
    display: block;
    margin: 0 auto 5px;
    height: 99px;
    width: auto;
    transition: 0.2s;
  }
}
.nav-game-overview__image.countdown-inactive {
  opacity: 0.4;
}
.nav-game-overview__container {
  position: relative;
}
.nav-game-overview__title {
  font-weight: 100;
}
@media all and (max-width: 949px) {
  .nav-game-overview__title.countdown-inactive {
    opacity: 0.5;
  }
}
@media all and (max-width: 949px) {
  .nav-game-overview__item {
    font-size: 4vw;
    display: block;
    width: 100%;
    text-align: left;
  }
  .nav-game-overview__link {
    padding: 4vw 4vw 4vw 6vw;
    color: #000;
    font-weight: 600;
    display: inline-block;
  }
  .nav-game-overview .nav-game-overview__title {
    font-weight: 600;
    font-size: 4vw;
  }
  .nav-game-overview__image {
    display: none;
  }
}
@media all and (min-width: 768px) and (max-width: 949px) {
  .nav-game-overview__item {
    font-size: 3vw;
  }
  .nav-game-overview__link {
    padding: 3vw 3vw 3vw 6vw;
  }
  .nav-game-overview .nav-game-overview__title {
    font-size: 3vw;
  }
}
.nav-game-overview .countdown-timer {
  position: absolute;
  top: 60%;
  right: -15%;
  transform: translateX(0) translateY(-40%);
  text-align: right;
  font-size: 0.85em;
  width: auto;
  display: block;
  font-weight: 900;
  opacity: 0.5;
}
@media all and (min-width: 950px) {
  .nav-game-overview .countdown-timer {
    left: 50%;
    top: 50%;
    right: auto;
    text-align: center;
    font-size: 1em;
    width: 100%;
    transform: translateX(-50%) translateY(-50%);
    opacity: 1;
  }
}
.nav-game-overview .countdown-timer:empty {
  display: none;
}
.notifications {
  transition: all 0.4s ease;
  position: relative;
}
.notifications.desktop-only {
  display: none;
}
@media all and (min-width: 768px) {
  .notifications.mobile-only {
    display: none;
  }
  .notifications.desktop-only {
    display: block;
  }
}
.notifications-item {
  display: none;
  width: 100%;
  background-color: #FFF;
  text-align: center;
  padding: 2.5vw 2vw;
  box-sizing: border-box;
  animation-name: flipInX;
  animation-duration: 0.8s;
  border-top: 1px solid #eee;
}
@media all and (min-width: 768px) {
  .notifications-item {
    padding: 7px 18px;
  }
}
.notifications-item .notifications-cta {
  display: inline-block;
  background-color: #014b33;
  color: white;
  font-size: 13px;
  padding: 1rem 2rem;
  margin-top: 1rem;
  border-radius: 3px;
  text-decoration: none;
}
.notifications-item .notifications-cta:hover {
  background-color: #016444;
}
.notifications-item .notifications-cta:empty {
  display: none;
}
.notifications-item-inner {
  max-width: 1440px;
  width: 100%;
  min-height: 8vw;
  font-weight: 600;
  text-align: center;
  margin: 0 auto;
  padding-right: 6vw;
  position: relative;
  box-sizing: border-box;
  font-size: 3vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media all and (min-width: 768px) {
  .notifications-item-inner {
    padding-left: 4rem;
    padding-right: 4rem;
    min-height: 2.5rem;
    font-size: 13px;
  }
}
.pam-page.new-login-page .notifications-item-inner {
  padding-right: 0;
}
.notifications-item-inner span {
  display: block;
  position: relative;
  padding: 5px;
  padding-left: 8.5vw;
}
@media all and (min-width: 768px) {
  .notifications-item-inner span {
    padding-left: 40px;
  }
}
.notifications-item-inner span a {
  color: inherit;
  text-decoration: underline;
}
.notifications-item-inner span:before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50.5%);
  width: 8vw;
  height: 8vw;
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  margin-right: 3vw;
  background-position: 0;
  background-size: 8vw;
}
@media all and (min-width: 768px) {
  .notifications-item-inner span:before {
    width: 35px;
    height: 35px;
    background-size: 35px;
    margin-right: 10px;
  }
}
.notifications-item-inner span,
.notifications-item-inner button {
  vertical-align: middle;
  line-height: 1.3;
}
.notifications-item-inner button {
  width: 4vw;
  height: 4vw;
  background-color: transparent;
  background-image: url('/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons.svg#close_use');
  background-position: center;
  background-size: 4vw;
  background-repeat: no-repeat;
  border: none;
  float: right;
  padding: 2vw;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50.5%);
}
.ios-device .notifications-item-inner button {
  background-image: url('/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons/close.svg');
}
@media all and (min-width: 768px) {
  .notifications-item-inner button {
    width: 15px;
    height: 15px;
    padding: 5px;
    background-size: 15px;
  }
}
.pam-page.new-login-page .notifications-item-inner button {
  display: none;
}
.notifications-item.error-notification {
  font-weight: bold;
  background-color: #ffb800;
}
.notifications-item.error-notification .notifications-item-inner span:before {
  background: url('/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons.svg#error_black_use');
}
.ios-device .notifications-item.error-notification .notifications-item-inner span:before {
  background: url('/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons/error_black.svg');
}
.notifications-item.info-notification .notifications-item-inner span:before {
  background: url('/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons.svg#info_use');
}
.ios-device .notifications-item.info-notification .notifications-item-inner span:before {
  background: url('/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons/info.svg');
}
.notifications-item.success-notification .notifications-item-inner span:before {
  background: url('/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons.svg#success_use');
}
.ios-device .notifications-item.success-notification .notifications-item-inner span:before {
  background: url('/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons/success.svg');
}
.notifications-item.warning-notification {
  font-weight: bold;
  background-color: #ffb800;
}
.notifications-item.warning-notification .notifications-item-inner span:before {
  background: url('/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons.svg#warning_black_use');
}
.ios-device .notifications-item.warning-notification .notifications-item-inner span:before {
  background: url('/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons/warning_black.svg');
}
@keyframes flipInX {
  0% {
    transform: perspective(400px) rotate3d(1, -100px, 0, -90deg);
  }
  50% {
    transform: perspective(400px) rotate3d(1, -20px, 0, -90deg);
  }
  100% {
    transform: perspective(400px);
  }
}
.region-avalon-livecasino .reality-check-notification-fallback,
.spillehjoernet-gamelauncher .reality-check-notification-fallback,
.region-avalon-spillehjoernet .reality-check-notification-fallback {
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: 3.6rem;
}
@media (min-width: 768px) and (max-width: 949px) {
  .region-avalon-livecasino .reality-check-notification-fallback,
  .spillehjoernet-gamelauncher .reality-check-notification-fallback,
  .region-avalon-spillehjoernet .reality-check-notification-fallback {
    top: 3.2rem;
  }
}
@media all and (max-width: 767px) {
  .region-avalon-livecasino .reality-check-notification-fallback,
  .spillehjoernet-gamelauncher .reality-check-notification-fallback,
  .region-avalon-spillehjoernet .reality-check-notification-fallback {
    top: 0;
  }
}
.region-avalon-bingo .reality-check-notification-fallback,
.region-avalon-bingo-gvc .reality-check-notification-fallback,
.region-avalon-bingo-slingo .reality-check-notification-fallback {
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: 3rem;
}
@media (min-width: 768px) and (max-width: 949px) {
  .region-avalon-bingo .reality-check-notification-fallback,
  .region-avalon-bingo-gvc .reality-check-notification-fallback,
  .region-avalon-bingo-slingo .reality-check-notification-fallback {
    top: 3.2rem;
  }
}
.region-frontpage .reality-check-static-fix {
  height: 0;
}
.game-overlay {
  position: relative;
}
header.top-navigation .reality-check-notification {
  transition: all 0.4s ease;
  z-index: 2;
  position: relative;
}
.reality-check-notification__content {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  box-shadow: 0 0 3px -1px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 0;
  box-sizing: border-box;
  background: white;
  color: black;
  justify-content: center;
  font-size: 11px;
  line-height: 1.2;
}
@media all and (min-width: 950px) {
  .reality-check-notification__content {
    flex-direction: row;
    align-items: center;
    font-size: 13px;
  }
}
.reality-check-notification__content .reality-check-notification__text {
  margin: 0.8rem 0;
  position: relative;
}
.reality-check-notification__content--normal.reality-check-notification__content .reality-check-notification__text,
.reality-check-notification__content--warning.reality-check-notification__content .reality-check-notification__text {
  padding-left: 45px;
}
.reality-check-notification__content--normal.reality-check-notification__content .reality-check-notification__text::before,
.reality-check-notification__content--warning.reality-check-notification__content .reality-check-notification__text::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  content: "";
  width: 40px;
  height: 100%;
  background-image: url('/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons/info.svg');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 40px 40px;
}
.reality-check-notification__content .reality-check-notification__text a {
  color: #007dc5;
  text-decoration: underline;
}
.reality-check-notification__content .reality-check-notification__text a:hover {
  text-decoration: none;
}
.reality-check-notification__content .reality-check-notification__text .amount-plus {
  color: #080;
}
.reality-check-notification__content .reality-check-notification__text .amount-minus {
  color: #d81818;
}
.reality-check-notification__content .reality-check-notification__button-container {
  display: flex;
  flex-direction: row-reverse;
}
@media (min-width: 950px) {
  .reality-check-notification__content .reality-check-notification__button-container {
    flex-direction: row;
    margin-right: 10px;
  }
}
.reality-check-notification__content .reality-check-notification__button {
  box-sizing: border-box;
  min-height: 30px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0;
  font-weight: bold;
  border-radius: 0;
  text-decoration: none;
  text-transform: uppercase;
  border: 1px solid transparent;
  color: black;
}
@media (min-width: 950px) {
  .reality-check-notification__content .reality-check-notification__button {
    margin-left: 1rem;
    padding: 5px 10px;
    border: 1px solid transparent;
    border-radius: 4px;
  }
}
.reality-check-notification__content .reality-check-notification__button--continue {
  text-decoration: none;
  background: #fb0;
  border-color: #fb0;
}
@media (min-width: 950px) {
  .reality-check-notification__content .reality-check-notification__button--continue {
    font-weight: bold;
  }
}
.reality-check-notification__content--warning.reality-check-notification__content .reality-check-notification__button--continue {
  background: white;
  border-color: #222;
}
.reality-check-notification__content .reality-check-notification__button--cancel {
  background: white;
  border-color: #222;
}
@media (min-width: 950px) {
  .reality-check-notification__content .reality-check-notification__button--cancel {
    text-transform: none;
    font-weight: normal;
  }
  .reality-check-notification__content .reality-check-notification__button--cancel > div {
    white-space: nowrap;
  }
}
:has(.responsible-gaming__top) .time-consuming-notification-static-fix {
  display: none;
}
:has(.responsible-gaming__top) .time-consuming-notification {
  display: none;
}
.region-avalon-livecasino .time-consuming-notification.fallback,
.region-avalon-bingo .time-consuming-notification.fallback,
.region-avalon-bingo-gvc .time-consuming-notification.fallback,
.region-avalon-bingo-slingo .time-consuming-notification.fallback,
.spillehjoernet-gamelauncher .time-consuming-notification.fallback,
.region-avalon-spillehjoernet .time-consuming-notification.fallback {
  position: fixed;
  z-index: 1000;
  width: 100%;
}
.region-avalon-bingo-gvc.is-mobile-detected .time-consuming-notification.fallback {
  position: relative;
  display: block;
  z-index: 1000;
  width: 100%;
}
.time-consuming-notification-wrapper {
  transition: all 0.4s ease;
  z-index: 2;
  position: relative;
}
.time-consuming-notification-static-fix {
  height: 3.6rem;
}
@media (min-width: 768px) and (max-width: 949px) {
  .time-consuming-notification-static-fix {
    height: 3.2rem;
  }
}
@media all and (max-width: 767px) {
  .time-consuming-notification-static-fix {
    height: 3rem;
    font-size: 1.1rem;
  }
}
body.time-consuming-notification-channel-disabled .time-consuming-notification-static-fix,
body.time-consuming-notification-channel-disabled .time-consuming-notification {
  display: none;
}
.is-showing-left-menu .time-consuming-notification {
  display: none;
}
.time-consuming-notification {
  font-family: var(--font-family-danskespil);
  padding: 0;
  height: 3.6rem;
  background-color: white;
  transition: all 0.4s ease;
  z-index: 2;
  position: relative;
  font-size: 1.3rem;
  font-weight: 400;
  color: #222;
}
@media (min-width: 768px) and (max-width: 949px) {
  .time-consuming-notification {
    height: 3.2rem;
  }
}
@media all and (max-width: 767px) {
  .time-consuming-notification {
    height: 3rem;
    font-size: 1.1rem;
  }
}
@media all and (max-width: 374px) {
  .time-consuming-notification {
    font-size: 0.84rem;
  }
}
.time-consuming-notification__content {
  display: flex;
  justify-content: space-between;
  background: white;
  height: inherit;
}
.time-consuming-notification__link {
  margin: 0.5rem;
}
@media (min-width: 768px) and (max-width: 949px) {
  .time-consuming-notification__link {
    margin: 0.6rem;
  }
}
@media all and (max-width: 767px) {
}
.time-consuming-notification__link a {
  color: black;
  text-decoration: none;
}
.time-consuming-notification__icon {
  height: 2.6rem;
  width: 2.2rem;
}
@media (min-width: 768px) and (max-width: 949px) {
  .time-consuming-notification__icon {
    height: 2.2rem;
    width: 2.2rem;
  }
}
@media all and (max-width: 767px) {
  .time-consuming-notification__icon {
    height: 2.2rem;
    width: 2.2rem;
  }
}
.time-consuming-notification__header-text {
  font-weight: 300;
  flex: 1 1;
  text-align: center;
  margin: 1.1rem 0 0.9rem;
  line-height: 1.3rem;
  font-size: 1.3rem;
}
@media (min-width: 768px) and (max-width: 949px) {
  .time-consuming-notification__header-text {
    margin: 1.1rem 0 0.8rem;
    line-height: 1.2rem;
    font-size: 1.1rem;
  }
}
@media all and (max-width: 767px) {
  .time-consuming-notification__header-text {
    margin: 0.9rem 0.5rem;
    font-size: 1rem;
  }
}
@media all and (max-width: 374px) {
  .time-consuming-notification__header-text {
    font-size: 0.84rem;
  }
}
.time-consuming-notification__timer {
  margin: 1.1rem 0 0.9rem;
  line-height: 1.4rem;
  display: flex;
  justify-content: flex-end;
  font-size: 1.3rem;
  text-align: end;
}
.time-consuming-notification__timer--hidden {
  display: none;
}
@media (min-width: 768px) and (max-width: 949px) {
  .time-consuming-notification__timer {
    margin: 1.1rem 0 0.8rem;
    line-height: 1.2rem;
    font-size: 1.1rem;
  }
}
@media all and (max-width: 767px) {
  .time-consuming-notification__timer {
    margin: 0.8rem 0.5rem 1rem;
    font-size: 1rem;
  }
}
@media all and (max-width: 374px) {
  .time-consuming-notification__timer {
    font-size: 0.84rem;
  }
}
.time-consuming-notification__timer-item {
  width: 4.5rem;
}
.time-consuming-notification__timer-icon {
  height: 1.4rem;
  width: 2rem;
  margin: 1.1rem;
}
@media (min-width: 768px) and (max-width: 949px) {
  .time-consuming-notification__timer-icon {
    height: 1.2rem;
    margin: 0.8rem;
  }
}
@media all and (max-width: 767px) {
  .time-consuming-notification__timer-icon {
    margin: 0.8rem 0;
    height: 1.2rem;
  }
}
.time-consuming-notification__stopwatch {
  height: 1.6rem;
  margin: 0.9rem 1rem 1.1rem;
}
@media all and (max-width: 767px) {
  .time-consuming-notification__stopwatch {
    height: 1.4rem;
    margin: 0.8rem 0 0.8rem;
  }
}
.operations-messenger > .visible .dark-background {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9990;
}
.operations-messenger > .visible .operations-messenger-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9990;
  display: flex;
  align-items: center;
  justify-content: center;
}
.operations-messenger > .visible .operations-messenger-content {
  width: 100%;
  max-width: 480px;
  min-height: 30rem;
  z-index: 10000;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.5);
  border-radius: 0.4rem;
}
@media only screen and (max-width: 480px) {
  .operations-messenger > .visible .operations-messenger-content {
    border-radius: 0;
  }
}
.operations-messenger > .visible .operations-messenger-content.slide-in {
  animation: slideIn 0.2s ease-out;
}
.operations-messenger > .visible .operations-messenger-content div.top-bar {
  width: 100%;
  height: 1.6rem;
  background-color: #fde025;
  border-radius: 0.4rem 0.4rem 0 0;
}
.operations-messenger > .visible .operations-messenger-content div.icon-circle {
  width: 4rem;
  height: 4rem;
  background: #fde025;
  margin: 2.5rem 0 1.5rem 0;
  border-radius: 50%;
}
.operations-messenger > .visible .operations-messenger-content div.icon-circle div.icon {
  width: 4rem;
  height: 4rem;
  background-image: url(/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons.svg#operation_messenger_exclamation_use);
}
.operations-messenger > .visible .operations-messenger-content .operations-messenger-heading {
  text-align: center;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  line-height: 2.4rem;
}
.operations-messenger > .visible .operations-messenger-content p {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
.operations-messenger > .visible .operations-messenger-content .operations-messenger-text {
  text-align: center;
  margin: 0 auto 2rem auto;
}
.operations-messenger > .visible .operations-messenger-content .operations-messenger-brand-plugin {
  box-sizing: border-box;
  position: relative;
  text-align: center;
  width: 100%;
  padding: 1rem 0 2.5rem 0;
}
.operations-messenger > .visible .operations-messenger-content .operations-messenger-brand-plugin p {
  font-weight: 700;
  margin-left: 2rem;
  margin-right: 2rem;
}
.operations-messenger > .visible .operations-messenger-content .operations-messenger-brand-plugin .arrow {
  position: absolute;
  top: 35%;
  right: 2rem;
  width: 2.7rem;
  height: 2.7rem;
  background-image: url(/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons.svg#operation_messenger_arrow_use);
}
.operations-messenger > .visible .operations-messenger-content .operations-messenger-brand-plugin .brand-logo {
  max-height: 7.5rem;
  max-width: 32rem;
}
.operations-messenger > .visible .operations-messenger-content .operations-messenger-notification-plugin {
  box-sizing: border-box;
  text-align: center;
  background: #eee;
  width: 100%;
  padding: 1rem 0;
}
.operations-messenger > .visible .operations-messenger-content .operations-messenger-notification-plugin h3 {
  font-weight: 700;
  font-size: 1.4rem;
  min-height: 1.6rem;
}
.operations-messenger > .visible .operations-messenger-content .operations-messenger-notification-plugin p {
  margin-bottom: 0.5rem;
}
.operations-messenger > .visible .operations-messenger-content .operations-messenger-notification-plugin .operations-messenger-notification-plugin__content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 1.8rem 2.6rem 1.7rem;
}
@media only screen and (max-width: 480px) {
  .operations-messenger > .visible .operations-messenger-content .operations-messenger-notification-plugin .operations-messenger-notification-plugin__content {
    flex-direction: column;
    margin-bottom: 0.3rem;
  }
}
.operations-messenger > .visible .operations-messenger-content .operations-messenger-notification-plugin input {
  font-weight: 700;
  font-size: 1.4rem;
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
  border-radius: 0.4rem;
  width: 31.5rem;
  height: 4rem;
  padding: 0 1rem;
  flex: 0 0 auto;
}
.operations-messenger > .visible .operations-messenger-content .operations-messenger-notification-plugin ::-webkit-input-placeholder {
  color: #999;
  font-weight: 400;
}
.operations-messenger > .visible .operations-messenger-content .operations-messenger-notification-plugin ::-moz-placeholder {
  color: #999;
  font-weight: 400;
}
.operations-messenger > .visible .operations-messenger-content .operations-messenger-notification-plugin :-ms-input-placeholder {
  color: #999;
  font-weight: 400;
}
.operations-messenger > .visible .operations-messenger-content .operations-messenger-notification-plugin button {
  background: #ffb800;
  font-weight: 700;
  font-size: 1.4rem;
  height: 4rem;
  width: 9.3rem;
  border-width: 0;
  border-radius: 0.4rem;
}
.operations-messenger > .visible .operations-messenger-content .operations-messenger-notification-plugin .validation-message {
  margin: 0;
  color: #c50000;
}
.operations-messenger > .visible .operations-messenger-content .operations-messenger-notification-subscribed {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 11.7rem;
  padding: 1rem;
  background: #eee;
}
.operations-messenger > .visible .operations-messenger-content .operations-messenger-notification-subscribed h3 {
  font-weight: 700;
  font-size: 1.4rem;
}
.operations-messenger > .visible .operations-messenger-content .unsubscribe-link-text {
  font-size: 1.2rem;
  margin-top: 0.6rem;
}
.operations-messenger > .visible .operations-messenger-content .unsubscribe-link-text a:link,
.operations-messenger > .visible .operations-messenger-content .unsubscribe-link-text a:visited {
  color: black;
}
.operations-messenger > .visible .operations-messenger-content .unsubscribe-link-text a:hover,
.operations-messenger > .visible .operations-messenger-content .unsubscribe-link-text a:active {
  color: grey;
}
.operations-messenger > .visible .operations-messenger-content .operations-messenger-button-close {
  text-align: center;
  padding: 1rem;
  border-width: 1px;
  border-style: solid;
  border-color: #000000;
  border-radius: 0.4rem;
  cursor: pointer;
  background: white;
  margin: 1.5rem 2rem 2rem 2rem;
  font-weight: 700;
  font-size: 1.4rem;
}
.operations-messenger > .visible .operations-messenger-content-alternate {
  width: 100%;
  min-height: 4rem;
  z-index: 10000;
  background: white;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
}
.operations-messenger > .visible .operations-messenger-content-alternate.slide-in {
  animation: slideIn 0.2s ease-out;
}
.operations-messenger > .visible .operations-messenger-content-alternate div.icon-circle {
  width: 30px;
  height: 30px;
  background: #fde025;
  margin: 20px 30px 20px 20px;
  border-radius: 50%;
}
.operations-messenger > .visible .operations-messenger-content-alternate div.icon-circle div.icon {
  width: 30px;
  height: 30px;
  background-image: url(/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons.svg#operation_messenger_exclamation_use);
}
.operations-messenger > .visible .operations-messenger-content-alternate .operations-messenger-heading {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  line-height: 1.4;
  margin-top: 20px;
}
.operations-messenger > .visible .operations-messenger-content-alternate .operations-messenger-button-close {
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-color: #000000;
  border-radius: 0.4rem;
  cursor: pointer;
  background: white;
  font-weight: 700;
  font-size: 1.4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  margin: auto 2rem;
}
.operations-messenger > .visible .operations-messenger-button {
  display: none;
}
.operations-messenger > div:not(.visible) {
  /* plus button is visible when the message is hidden */
}
.operations-messenger > div:not(.visible) .operations-messenger-content,
.operations-messenger > div:not(.visible) .operations-messenger-content-alternate {
  display: none;
}
.operations-messenger > div:not(.visible) .operations-messenger-button {
  position: fixed;
  right: -1.5rem;
  top: 50%;
  width: 6rem;
  background: #fde025;
  z-index: 10000;
  font-size: 2rem;
  border-radius: 0.4rem 0 0 0.4rem;
  cursor: pointer;
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.49803922);
  transition: all 0.15s ease-out;
}
.operations-messenger > div:not(.visible) .operations-messenger-button:hover {
  right: 0;
}
.operations-messenger > div:not(.visible) .operations-messenger-button div.icon {
  width: 4.5rem;
  height: 4.5rem;
  background-image: url(/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons.svg#operation_messenger_exclamation_use);
}
@keyframes slideIn {
  0% {
    transform: translateX(100rem);
  }
}
