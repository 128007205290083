.ds-modal-popup {
  position: fixed;
  z-index: 10000;
  bottom: 3vh;
  left: 50%;
  max-height: 85vh;
  background-color: white;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100% - 17vw);
  max-width: 38rem;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transform: translate3d(-50%, 1rem, 0);
  transition: all 340ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.ds-modal-popup a:focus {
  outline: none;
}
.ds-modal-popup a:focus-visible,
.ds-modal-popup *:focus-visible {
  outline: #000000 dotted thin;
  outline-offset: 0.2rem;
}
.ds-modal-popup a:active,
.ds-modal-popup *:active {
  outline: none;
}
.ds-modal-popup--open {
  pointer-events: unset;
  visibility: unset;
  opacity: unset;
  transform: translate3d(-50%, 0, 0);
}
.ds-modal-popup a:active,
.ds-modal-popup button:active {
  transform: translateY(0.1rem);
}
@media (min-width: 768px) and (min-height: 768px) {
  .ds-modal-popup {
    max-width: 52rem;
    max-height: 70vh;
    top: 15vh;
    bottom: auto;
  }
}
.ds-modal-popup__close {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 2rem;
  right: 2rem;
  width: 3.6rem;
  height: 3.6rem;
  border: none;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 0 0.4rem transparent;
}
@media screen and (min-width: 768px) {
  .ds-modal-popup__close {
    top: 3rem;
    right: 4rem;
  }
}
.ds-modal-popup__close svg {
  position: absolute;
  top: 0.6rem;
  left: 0.6rem;
  color: #000000;
  width: 2.4rem;
  height: 2.4rem;
}
.ds-modal-popup--hide-close-x .ds-modal-popup__close {
  display: none;
}
.ds-modal-popup__inner {
  position: relative;
  top: auto;
  bottom: auto;
  width: 100%;
  overflow-y: auto;
  margin: 1rem 0;
  padding: 6.4rem 2.4rem 2.2rem;
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.2rem;
  color: #222222;
  text-align: center;
}
@media (min-width: 768px) and (min-height: 768px) {
  .ds-modal-popup__inner {
    padding: 6.4rem 4rem 3rem;
  }
}
.ds-modal-popup__icon {
  height: 7.2rem;
  margin-top: -4rem;
  margin-bottom: 2.4rem;
}
.ds-modal-popup__header {
  font-family: var(--font-family-danskespil);
  color: #000000;
  font-size: 2.8rem;
  line-height: 1.15;
  letter-spacing: -0.02em;
  font-style: normal;
  font-weight: 700;
}
@media (min-width: 768px) and (min-height: 768px) {
  .ds-modal-popup__header {
    font-size: 4rem;
  }
}
.ds-modal-popup__text {
  font-size: 1.4rem;
  line-height: 1.57;
  font-style: normal;
  font-weight: 400;
}
.ds-modal-popup__text br + br:nth-of-type(2) {
  display: block;
  content: "";
  margin-top: 1.2em;
}
.ds-modal-popup__text p {
  margin: 0 0 0.5em;
}
.ds-modal-popup__text a {
  color: #000000;
  text-decoration: underline;
}
@media (min-width: 768px) and (min-height: 768px) {
  .ds-modal-popup__text {
    font-size: 1.6rem;
  }
}
.ds-modal-popup__buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.6rem;
  margin: 1rem auto 0;
  max-width: 30.8rem;
}
@media (min-width: 768px) and (min-height: 768px) {
  .ds-modal-popup__buttons {
    margin-top: 2rem;
  }
}
.ds-modal-popup__button {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 0.8ch;
  width: 100%;
  padding: 0 1.2rem 0.1rem;
  flex: 0 0 5.6rem;
  border-radius: 10rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.4);
  color: #000000;
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 800;
  line-height: 1.1;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  background-color: white;
}
.ds-modal-popup__button:hover {
  text-decoration: none;
}
.ds-modal-popup__button b {
  font-weight: inherit;
}
@media (min-width: 768px) and (min-height: 768px) {
  .ds-modal-popup__button {
    font-size: 1.4rem;
    flex: 0 0 5.4rem;
  }
}
.ds-modal-popup__button--primary {
  background-color: #FEB700;
  border-color: #FEB700;
}
