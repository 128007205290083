@media only print {
  #printable * {
    background: transparent !important;
    color: #000;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  #printable a,
  #printable a:visited {
    text-decoration: underline;
  }
  #printable a[href]:after {
    content: " (" attr(href) ")";
  }
  #printable abbr[title]:after {
    content: " (" attr(title) ")";
  }
  #printable a[href^="javascript:"]:after,
  #printable a[href^="#"]:after {
    content: "";
  }
  #printable pre,
  #printable blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  #printable thead {
    display: table-header-group;
  }
  #printable tr,
  #printable img {
    page-break-inside: avoid;
  }
  #printable img {
    max-width: 100% !important;
  }
  #printable p,
  #printable h2,
  #printable h3 {
    orphans: 3;
    widows: 3;
  }
  #printable h2,
  #printable h3 {
    page-break-after: avoid;
  }
  #printable .aside-column,
  #printable .social-plugin,
  #printable .quick-button {
    display: none !important;
  }
  #printable .primary-column {
    width: 100%;
  }
  #printable .article .date-label,
  #printable .article .date {
    bottom: -40px;
    right: 0;
    height: auto;
    width: auto;
  }
  #printable .article .date-label span,
  #printable .article .date span {
    display: inline;
  }
  #printable .article .date-label span:last-child,
  #printable .article .date span:last-child {
    padding-left: 10px;
  }
  #printable .overlay-enabled {
    padding: 0;
    overflow: visible;
  }
  #printable .overlay-enabled form .page {
    display: none;
  }
  #printable .overlay-enabled .overlay-wrapper {
    overflow: visible;
    position: relative;
    width: 100%;
  }
  #printable .overlay-enabled .overlay {
    margin-top: 0;
    width: 100% !important;
    overflow: hidden;
    position: relative;
    top: 15mm;
    left: 10mm;
  }
  #printable .overlay-enabled .overlay:after {
    content: "";
    display: inherit;
    border-top: 900mm solid white;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
  }
  #printable .overlay-enabled .overlay .header {
    height: auto;
    width: 100%;
    border-bottom: 2px solid #999;
    margin-bottom: 6mm;
  }
  #printable .overlay-enabled .overlay .header .title {
    position: relative;
    top: 0;
    margin-bottom: 3mm;
    color: #999;
  }
  #printable .overlay-enabled .overlay .article {
    padding: 0 0 10mm 0;
  }
  #printable .overlay-enabled .overlay .close,
  #printable .overlay-enabled .overlay .footer {
    display: none;
  }
  #printable .region-dantoto {
    background: white !important;
  }
  #printable .region-dantoto .no-print {
    display: none;
  }
  #printable .region-dantoto #wrapper:before {
    background: none;
  }
  #printable .region-dantoto #wrapper .col-md-12,
  #printable .region-dantoto #wrapper .col-md-8 {
    width: 100%;
  }
  #printable .region-dantoto #wrapper .col-md-12 .style-article img.top,
  #printable .region-dantoto #wrapper .col-md-8 .style-article img.top {
    width: 66.66666667%;
  }
  #printable .region-dantoto #wrapper .col-md-12 .content,
  #printable .region-dantoto #wrapper .col-md-8 .content {
    margin: 1em 0;
  }
}
