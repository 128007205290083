.ds-cube-loader-wrapper--halfsize {
  transform: scale(0.5, 0.5);
}
.ds-cube-loader-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  transition: 300ms ease-in-out all;
}
.ds-cube-loader-wrapper:before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}
@keyframes giro {
  0% {
    transform: rotateX(-45deg) rotateY(-20deg);
  }
  50% {
    transform: rotateX(45deg) rotateY(360deg);
  }
  100% {
    transform: rotateX(-45deg) rotateY(700deg);
  }
}
@keyframes cubeRotationStop {
  0% {
    transform: rotateX(-45deg) rotateY(700deg);
  }
  100% {
    transform: translateY(-50%) rotateX(-15deg) rotateY(-10deg) scale(0.8);
  }
}
.ds-cube-loader-wrapper .ds-cube-wrapper {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  perspective: 1000px;
  perspective-origin: 50% 50%;
  transform: translateY(-50%) scale(0.25);
  z-index: 1;
}
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube,
.ds-cube-loader-wrapper .ds-cube-wrapper .cube-face,
.ds-cube-loader-wrapper .ds-cube-wrapper .cube-inner-face {
  height: 29rem;
  width: 29rem;
  box-sizing: content-box;
}
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube {
  transform-style: preserve-3d;
  transform: rotateX(-45deg) rotateY(-20deg);
  margin: 15rem auto;
  animation: giro 5s 0s infinite linear;
}
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-inner-face,
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-face {
  border: 5px solid #feb700;
}
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-face {
  position: absolute;
  border-radius: 30px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-face {
    background: #feb700;
  }
}
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-face:nth-child(1) {
  transform: translateZ(15rem);
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-face:nth-child(1) {
    z-index: 1;
  }
}
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-face:nth-child(2) {
  transform: rotateY(180deg) translateZ(15rem);
}
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-face:nth-child(3) {
  transform: rotateY(-90deg) translateZ(15rem);
}
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-face:nth-child(4) {
  transform: rotateY(90deg) translateZ(15rem);
}
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-face:nth-child(5) {
  transform: rotateX(90deg) translateZ(15rem);
}
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-face:nth-child(6) {
  transform: rotateX(-90deg) translateZ(15rem);
}
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-face .square,
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-face .diamond,
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-face .circle,
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-face .triangle {
  position: absolute;
}
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-face .square {
  width: 5.8rem;
  height: 5.8rem;
  left: 2.5rem;
  bottom: 2.5rem;
  background-color: #004b32;
}
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-face .diamond {
  width: 5.8rem;
  height: 10rem;
  left: 5.8rem;
  bottom: 10rem;
}
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-face .diamond:before,
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-face .diamond:after {
  content: ' ';
  width: 0;
  height: 0;
  border-left: 2.9rem solid transparent;
  border-right: 2.9rem solid transparent;
  position: absolute;
  left: 0;
}
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-face .diamond:before {
  border-bottom: 5rem solid #004b32;
  top: 0;
}
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-face .diamond:after {
  border-top: 5rem solid #004b32;
  top: 50%;
}
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-face .circle {
  width: 6.7rem;
  height: 6.7rem;
  background-color: #004b32;
  border-radius: 50%;
  bottom: 17.7rem;
  left: 12rem;
}
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-face .triangle {
  width: 0;
  height: 0;
  border-left: 3.5rem solid transparent;
  border-right: 3.5rem solid transparent;
  border-top: 6rem solid #004b32;
  top: 2.5rem;
  right: 2.5rem;
}
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-inner-face {
  background: #feb700;
  position: absolute;
  border-radius: 19px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-inner-face {
    background: transparent;
  }
}
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-inner-face:nth-child(7) {
  transform: translateZ(14.9rem);
}
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-inner-face:nth-child(8) {
  transform: rotateY(180deg) translateZ(14.9rem);
}
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-inner-face:nth-child(9) {
  transform: rotateY(-90deg) translateZ(14.9rem);
}
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-inner-face:nth-child(10) {
  transform: rotateY(90deg) translateZ(14.9rem);
}
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-inner-face:nth-child(11) {
  transform: rotateX(90deg) translateZ(14.9rem);
}
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-inner-face:nth-child(12) {
  transform: rotateX(-90deg) translateZ(14.9rem);
}
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-inner-face:nth-child(13) {
  transform: rotateX(180deg);
}
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-inner-face:nth-child(14) {
  transform: rotateY(90deg);
}
.ds-cube-loader-wrapper .ds-cube-wrapper .ds-cube .cube-inner-face:nth-child(15) {
  transform: rotateX(90deg);
}
@keyframes loaderFacesFadeAnimation {
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
@keyframes loaderSadCubeShake {
  0% {
    transform: translateY(-50%) rotateX(-15deg) rotateY(-10deg) scale(0.8);
  }
  10% {
    transform: translateY(-50%) rotateX(-35deg) rotateY(-10deg) scale(0.8);
  }
  11% {
    transform: translateY(-50%) rotateX(-35deg) rotateY(-10deg) scale(0.8);
  }
  30% {
    transform: translateY(-50%) rotateX(-35deg) rotateY(30deg) scale(0.8);
  }
  32% {
    transform: translateY(-50%) rotateX(-35deg) rotateY(33deg) scale(0.8);
  }
  60% {
    transform: translateY(-50%) rotateX(-35deg) rotateY(-30deg) scale(0.8);
  }
  62% {
    transform: translateY(-50%) rotateX(-35deg) rotateY(-33deg) scale(0.8);
  }
  68% {
    transform: translateY(-50%) rotateX(-35deg) rotateY(-13deg) scale(0.8);
  }
  70% {
    transform: translateY(-50%) rotateX(-35deg) rotateY(-10deg) scale(0.8);
  }
  80% {
    transform: translateY(-50%) rotateX(-15deg) rotateY(-10deg) scale(0.8);
  }
  85% {
    transform: translateY(-50%) rotateX(-10deg) rotateY(-10deg) scale(0.8);
  }
  100% {
    transform: translateY(-50%) rotateX(-15deg) rotateY(-10deg) scale(0.8);
  }
}
@keyframes emojiEyesBlink {
  10% {
    transform: scaleY(1);
  }
  11% {
    transform: scaleY(0.2);
  }
  12% {
    transform: scaleY(1);
  }
  13% {
    transform: scaleY(0.2);
  }
  14% {
    transform: scaleY(1);
  }
  30% {
    transform: scaleY(1);
  }
  31% {
    transform: scaleY(0.2);
  }
  32% {
    transform: scaleY(1);
  }
  70% {
    transform: scaleY(1);
  }
  71% {
    transform: scaleY(0.2);
  }
  72% {
    transform: scaleY(1);
  }
  90% {
    transform: scaleY(1);
  }
  91% {
    transform: scaleY(0.2);
  }
  92% {
    transform: scaleY(1);
  }
}
@keyframes loadeEmojiFadeIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes sadEmojiMouthUpperLip {
  0% {
    border-radius: 50%;
    opacity: 1;
  }
  99.9% {
    opacity: 1;
  }
  100% {
    border-radius: 0%;
    opacity: 0;
  }
}
@keyframes sadEmojiMouthLowerLip {
  0% {
    border-radius: 0%;
    opacity: 0;
  }
  0.1% {
    opacity: 1;
  }
  100% {
    border-radius: 50%;
    opacity: 1;
  }
}
@keyframes sadEmojiMouthLowerLipPulse {
  5% {
    padding-top: 100%;
  }
  7% {
    padding-top: 300%;
  }
  15% {
    padding-top: 300%;
  }
  17% {
    padding-top: 100%;
  }
  20% {
    padding-top: 100%;
  }
  22% {
    padding-top: 300%;
  }
  28% {
    padding-top: 300%;
  }
  30% {
    padding-top: 100%;
  }
  50% {
    padding-top: 100%;
  }
  52% {
    padding-top: 200%;
  }
  72% {
    padding-top: 200%;
  }
  74% {
    padding-top: 100%;
  }
}
@keyframes sadEmojiEyes {
  100% {
    transform: translateY(50%);
  }
}
@keyframes sadLeftEyebrow {
  100% {
    width: 5rem;
    transform: rotate(15deg) translate(30%, -50%);
  }
}
@keyframes sadRightEyebrow {
  100% {
    width: 5rem;
    transform: rotate(-15deg) translate(-30%, -50%);
  }
}
@keyframes sadEmojiEyebrowsPulse {
  9% {
    transform: translateY(0);
  }
  11% {
    transform: translateY(200%);
  }
  12% {
    transform: translateY(100%);
  }
  13% {
    transform: translateY(200%);
  }
  15% {
    transform: translateY(0);
  }
  28% {
    transform: translateY(0);
  }
  31% {
    transform: translateY(200%);
  }
  33% {
    transform: translateY(0);
  }
  68% {
    transform: translateY(0);
  }
  71% {
    transform: translateY(200%);
  }
  73% {
    transform: translateY(0);
  }
  89% {
    transform: translateY(0);
  }
  91% {
    transform: translateY(200%);
  }
  93% {
    transform: translateY(0);
  }
}
.ds-cube-loader-wrapper .ds-cube-wrapper.error .ds-cube {
  transform: translateY(-50%) rotateX(-15deg) rotateY(-10deg) scale(0.8);
  animation: cubeRotationStop 0.25s forwards ease-out, loaderSadCubeShake 3s 0.5s infinite linear;
}
.ds-cube-loader-wrapper .ds-cube-wrapper.error .ds-cube .cube-face:nth-child(1) .square,
.ds-cube-loader-wrapper .ds-cube-wrapper.error .ds-cube .cube-face:nth-child(1) .diamond,
.ds-cube-loader-wrapper .ds-cube-wrapper.error .ds-cube .cube-face:nth-child(1) .circle,
.ds-cube-loader-wrapper .ds-cube-wrapper.error .ds-cube .cube-face:nth-child(1) .triangle {
  animation: loaderFacesFadeAnimation 0.25s forwards linear;
}
.ds-cube-loader-wrapper .ds-cube-wrapper.error .ds-cube .cube-face .emoji-face {
  animation: loadeEmojiFadeIn 0.15s 0.15s forwards linear;
}
.ds-cube-loader-wrapper .ds-cube-wrapper.error .ds-cube .cube-face .emoji-face .eyes {
  animation: sadEmojiEyes 0.1s 0.5s forwards linear;
}
.ds-cube-loader-wrapper .ds-cube-wrapper.error .ds-cube .cube-face .emoji-face .eyes:before,
.ds-cube-loader-wrapper .ds-cube-wrapper.error .ds-cube .cube-face .emoji-face .eyes:after {
  animation: emojiEyesBlink 20s 0.6s infinite linear;
}
.ds-cube-loader-wrapper .ds-cube-wrapper.error .ds-cube .cube-face .emoji-face .eyes .eyebrows {
  animation: sadEmojiEyebrowsPulse 20s 0.6s infinite linear;
}
.ds-cube-loader-wrapper .ds-cube-wrapper.error .ds-cube .cube-face .emoji-face .eyes .eyebrows:before {
  animation: sadRightEyebrow 0.15s 0.65s forwards linear;
}
.ds-cube-loader-wrapper .ds-cube-wrapper.error .ds-cube .cube-face .emoji-face .eyes .eyebrows:after {
  animation: sadLeftEyebrow 0.15s 0.65s forwards linear;
}
.ds-cube-loader-wrapper .ds-cube-wrapper.error .ds-cube .cube-face .emoji-face .mouth:before {
  animation: sadEmojiMouthLowerLip 0.15s 0.55s forwards linear, sadEmojiMouthLowerLipPulse 15s 0.6s infinite linear;
}
.ds-cube-loader-wrapper .ds-cube-wrapper.error .ds-cube .cube-face .emoji-face .mouth:after {
  animation: sadEmojiMouthUpperLip 0.1s 0.45s forwards linear;
}
.ds-cube-loader-wrapper .ds-cube-loader-text {
  width: 80%;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  margin: auto;
  margin-top: 1.5rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 70%;
  opacity: 0;
}
.ds-cube-loader-wrapper .ds-cube-loader-text .ds-cube-loader-text-headline {
  font-size: 2rem;
  font-weight: bold;
}
.ds-cube-loader-wrapper .ds-cube-loader-text .ds-cube-loader-text-description {
  margin-top: 1rem;
  font-size: 1.6rem;
  font-weight: normal;
}
.ds-cube-loader-wrapper .ds-cube-loader-text.error {
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}
.ds-cube-loader-wrapper .emoji-face {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.ds-cube-loader-wrapper .emoji-face .eyes {
  width: 60%;
  height: 5rem;
  position: absolute;
  top: 25%;
  left: 20%;
}
.ds-cube-loader-wrapper .emoji-face .eyes:before,
.ds-cube-loader-wrapper .emoji-face .eyes:after {
  content: '';
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: #004b32;
  position: absolute;
  top: 0;
}
.ds-cube-loader-wrapper .emoji-face .eyes:before {
  left: 0;
}
.ds-cube-loader-wrapper .emoji-face .eyes:after {
  right: 0;
}
.ds-cube-loader-wrapper .emoji-face .eyes .eyebrows {
  width: 100%;
  height: 1rem;
  position: absolute;
  top: -60%;
  left: 0;
}
.ds-cube-loader-wrapper .emoji-face .eyes .eyebrows:before,
.ds-cube-loader-wrapper .emoji-face .eyes .eyebrows:after {
  content: '';
  width: 4rem;
  height: 1rem;
  border-radius: 1rem;
  background-color: #004b32;
  position: absolute;
  top: 0;
}
.ds-cube-loader-wrapper .emoji-face .eyes .eyebrows:before {
  left: 0.5rem;
}
.ds-cube-loader-wrapper .emoji-face .eyes .eyebrows:after {
  right: 0.5rem;
}
.ds-cube-loader-wrapper .emoji-face .mouth {
  width: 40%;
  padding-top: 40%;
  position: absolute;
  bottom: 5%;
  left: 30%;
  overflow: hidden;
}
.ds-cube-loader-wrapper .emoji-face .mouth:before,
.ds-cube-loader-wrapper .emoji-face .mouth:after {
  content: ' ';
  width: 200%;
  padding-top: 100%;
  border-radius: 0;
  border-width: 1.5rem;
  border-style: solid;
  border-color: #004b32;
  position: absolute;
  left: 50%;
}
.ds-cube-loader-wrapper .emoji-face .mouth:before {
  top: 50%;
  transform: translateX(-50%) translateY(-0.75rem);
  opacity: 0;
}
.ds-cube-loader-wrapper .emoji-face .mouth:after {
  border-radius: 50%;
  bottom: 50%;
  transform: translateX(-50%) translateY(0.75rem);
}
